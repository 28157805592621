// import { getToken } from 'utils/jwt';
import api from './axios';

export type Recognize = {
  id: string;
  userId: string;
  value: number;
  createdBy: string;
  companyId: string;
  createdAt: string;
  transactionType: string;
  additionalInformation: {
    companyValue?: string;
    companyValues?: string[];
    message: string;
    gif: string;
  };
};

export type AddRecognize = {
  companyValue: string;
  message: string;
  meritus: number;
  companyId: string;
  gif?: string;
};

export interface getRecognizeResponse extends Recognize {
  to?: { displayName: string; id: string; img: string };
  from?: { displayName: string; id: string; img: string };
}

export type listRecognizeResponse = getRecognizeResponse[];

export const getRecognize = async (recognizeId: Recognize['id']) => {
  const { data } = await api.get<getRecognizeResponse>(
    `recognize/${recognizeId}`,
    {}
  );
  return data;
};

// export const listCompanyRecognize = async (companyId: string) => {
//   const { data } = await api.get<listRecognizeResponse>(
//     `transactions/company/${companyId}`,
//     { headers: { Authorization: `Bearer ${getToken()}` } }
//   );
//   return data;
// };

// export const listUserRecognize = async (userId: string) => {
//   const { data } = await api.get<listRecognizeResponse>(
//     `/transactions/user/${userId}`,
//     { headers: { Authorization: `Bearer ${getToken()}` } }
//   );
//   return data;
// };

export const addRecognize = async (
  recognizeId: Recognize['id'],
  params: AddRecognize
) => {
  const response = await api.request({
    method: 'post',
    url: `recognize/${recognizeId}`,
    data: params,
  });
  return response;
};
