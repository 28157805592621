import React from 'react';

import { ErrorMessage, Field, FieldProps } from 'formik';
import ReactDatePicker from 'react-date-picker';

interface DatePickerProps {
  name: string;
  className?: string;
}
const DatePicker: React.FC<DatePickerProps> = (props) => {
  return <Field component={DatePickerChild} {...props} />;
};

interface DatePickerChildProps extends FieldProps, DatePickerProps {}

const DatePickerChild: React.FC<DatePickerChildProps> = ({
  className,
  field,
  form,
  meta,
}) => {
  return (
    <div className={`${className} relative`}>
      <ReactDatePicker
        onChange={(date: Date) => {
          form.setFieldValue(field.name, date);
        }}
        value={field.value}
        locale="pt-br"
      />
      <p className="text-xs text-red absolute font-bold">
        <ErrorMessage name={field.name} />
      </p>
    </div>
  );
};

export default DatePicker;
