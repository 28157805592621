import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Result, searchGif } from 'utils/tenor';
import { useMediaQuery } from 'react-responsive';

import { ErrorMessage, Field, FieldProps } from 'formik';
import Arrow from './Arrow';
import { i18n } from 'utils/i18n/index';

interface TenorProps {
  name: string;
  className?: string;
}

const Tenor: React.FC<TenorProps> = (props) => {
  return <Field component={TenorChild} {...props} />;
};

interface TenorChildProps extends FieldProps, TenorProps {}

const TenorChild: React.FC<TenorChildProps> = ({
  className,
  field,
  form,
  meta,
}) => {
  const isLarge = useMediaQuery({ minWidth: 1024 });
  // const [gifs, setGifs] = useState<Result[]>([]);
  const [gifChunks, setGifChunks] = useState<Result[][]>([[]]);
  const [chunkIndex, setChunkIndex] = useState(0);
  const [term, setTerm] = useState('');
  const [next, setNext] = useState('');

  useEffect(() => {
    setChunkIndex(0);
    searchGif().then((values) => {
      setGifChunks([values.data.results]);
      setNext(values.data.next);
    });
  }, []);

  const handleSearch = (e: React.MouseEvent) => {
    e.preventDefault();
    setChunkIndex(0);
    form.setFieldValue(field.name, {});
    searchGif(term, 10).then((values) => {
      setGifChunks([values.data.results]);
    });
  };

  const handleNext = () => {
    form.setFieldValue(field.name, {});
    if (gifChunks[chunkIndex + 1]) {
      setChunkIndex(chunkIndex + 1);
    } else {
      searchGif(term, 10, next).then((values) => {
        setNext(values.data.next);
        setGifChunks([...gifChunks, values.data.results]);
        setChunkIndex(chunkIndex + 1);
      });
    }
  };

  const handleBack = () => {
    form.setFieldValue(field.name, {});
    if (chunkIndex > 0) {
      setChunkIndex(chunkIndex - 1);
    }
  };

  return (
    <div className={className}>
      <div className="flex justify-between p-3 rounded-input border border-lighter-70">
        <input
          placeholder= {i18n.t("searchTenor")}
          className="outline-none w-full"
          onChange={(e) => setTerm(e.target.value)}
          value={term}
        />
        <button onClick={handleSearch}>{i18n.t("search")}</button>
      </div>
      <div className="flex items-center">
        <Arrow
          className="bg-lighter-70 w-12 rounded-full p-2 cursor-pointer"
          onClick={handleBack}
        />
        <div className="flex gap-1 flex-wrap items-center justify-around mt-3">
          {gifChunks[chunkIndex].map((gif, index) => {
            const num = isLarge ? 10 : 6;
            if (index >= num) return <></>;
            return (
              <div
                className={`w-1/4 lg:w-1/6 cursor-pointer 
                      ${
                        gif.id === field.value.id
                          ? 'border-4 border-secondary'
                          : 'border-1'
                      }
                      `}
                key={gif.id}
                onClick={() =>
                  form.setFieldValue(field.name, {
                    id: gif.id,
                    url: gif.media[0].gif.url,
                    term,
                  })
                }
              >
                <img src={gif.media[0].tinygif.url} alt="" />
                <p className="text-xs text-red absolute">
                  <ErrorMessage name={field.name} />
                </p>
              </div>
            );
          })}
        </div>
        <Arrow
          right
          className="bg-lighter-70 w-12 rounded-full p-2 cursor-pointer"
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default Tenor;
