import React from 'react';

import { ReactComponent as BriefcaseIcon } from '../../../../assets/vectors/briefcase.svg';
import photo from '../../../../assets/images/empresas.png';
import { CompanyModel } from 'utils/api/company';
import Square from 'Components/Square';
import GhostProfilePicture from 'Components/Ghost/GhostProfilePicture';
import ReadMore from 'Components/ReadMore';

interface CompanyDescProps {
  company: Partial<CompanyModel>;
}

const CompanyDesc: React.FC<CompanyDescProps> = (props) => {
  return (
    <div className="flex items-center justify-center lg:grid lg:grid-cols-7 mb-5 mt-10">
      <main className="col-span-4">
        <div className="flex flex-col lg:flex-row items-center gap-3">
          <div className="w-24">
            <GhostProfilePicture
              src={props.company.logo}
              alt="company logo"
              name={props.company.name}
            />
          </div>
          <div className="text-center lg:text-left">
            <p className="text-2xl lg:text-4xl text-black font-medium">{props.company.name}</p>
            <p className="text-black">{props.company.slogan}</p>
          </div>
        </div>
        <ReadMore
          className="mt-4 mb-16"
          text={props.company.desc || ''}
          numSplit={300}
        />
        <div className="lg:grid lg:grid-cols-2 gap-5">
          <div>
            <BriefcaseIcon />
            <p className="mt-3 mb-2 text-lg text-black">Valores</p>
            <p className="text-sm">
              {props.company.values?.reduce(
                (acc, value) => `${acc} - ${value.name}`,
                ''
              )}
            </p>
          </div>
        </div>
      </main>
      <div className="hidden lg:flex max-h-screen w-full col-span-3 items-center justify-center">
        <div className="w-3/4 rounded-full relative">
          <img
            src={photo}
            alt=""
            className="rounded-full w-full h-auto z-10 absolute transform -translate-y-1/2"
          />
          <div className="bg-gray min-w-full h-screen transform bottom-0 absolute z-0 after"></div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDesc;
