import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import { Formik } from 'formik';
import AutoMargin from 'Components/AutoMargin';
import InputText from 'Components/Formik/InputText';
import { updateUserProfile, updateUserProfileParams } from 'utils/api/users';
import { UpdateUserPassword } from 'utils/validators/updateProfile';
import { toast } from 'react-toastify';
import { i18n } from 'utils/i18n/index';

interface UpdateUserProps {
    cancel: () => void;
  } 


const ChangePassword:React.FC<UpdateUserProps> = ({cancel}) => { 
    const initialValues = {
        password: '',
        confirm: '',
      };
          
    const { user, updateUserContext } = useContext(UserContext);
    
    const onSubmit = (values: typeof initialValues, actions: any) => {
        actions.setSubmitting(true);
        const { password } = values;
        const payload: updateUserProfileParams =  { password }
        
        toast.promise(updateUserProfile(payload), {
            pending: `${i18n.t("changingPassword")}`,
            success: {
              render() {
                actions.setSubmitting(false);
                cancel();
                return `${i18n.t("passwordChanged")}`;
              },
            },
            error: {
              render({ data }) {
                actions.setSubmitting(false);
                return `${i18n.t("somethingWrong")}`;
              },
            },
          });
    }
    return(
        <AutoMargin className="bg-white rounded w-full p-5 lg:p-10">
            <p className="text-black text-xl">{i18n.t("changePassword")}</p>
            <p>
              {i18n.t("fillTheInput")}
            </p>
            <Formik
            onSubmit={onSubmit}
            initialValues = {initialValues}
            validationSchema={UpdateUserPassword}
            >

            {({ isSubmitting, submitForm }) => (
               <div>
                    <label className="flex flex-col text-xs w-full mt-4">
                        {i18n.t("newPassword")}
                        <InputText
                        name="password"
                        password
                        placeholder= {i18n.t("typeTheNewPassword")}
                    />
                    </label>

                    <label className="flex flex-col text-xs w-full mt-4">
                        {i18n.t("repeatPassword")}
                        <InputText
                        name="confirm"
                        password
                        placeholder= {i18n.t("typeAgain")}
                    />
                    </label>
                    <div className="flex mt-7 justify-between">
                    <button
                        className="rounded-full px-8 py-1 border"
                        onClick={cancel}
                    >
                      {i18n.t("cancel")}
                    </button>
                    <button
                        className="rounded-full bg-primary-darker px-8 py-1 text-white disabled:opacity-30"
                        disabled={isSubmitting}
                        onClick={submitForm}
                    >
                    {i18n.t("change")}
                    </button>
                    </div>
                </div>
            )}
           
            </Formik>
        </AutoMargin>
    
    );
}

export default ChangePassword;
