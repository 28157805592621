import React, { useState, useEffect } from 'react';
import { getUser, logoutApi, UserModel, whoAmI } from 'utils/api/users';
import { getCompany, CompanyModel } from 'utils/api/company';
import history from 'utils/history';
// import { getTokenPayload, logout } from 'utils/jwt';
import { useRouteMatch } from 'react-router-dom';
import { loginCookie, logoutCookie } from 'utils/jwt';
import {
  getIdToken,
  ssoWithGoogle,
  ssoWithMicrosoft,
  ssoWithOidc,
} from 'utils/sso';
import { userAuthenticate, userAuthWithIdToken } from 'utils/api/authenticate';
import { toast } from 'react-toastify';

export type UserContext = {
  // undefined: not initialized, null: invalid session, string: valid session
  userId: string | undefined | null;
  user: Partial<UserModel> | undefined | null;
  manager: string[] | undefined;
  setUser: React.Dispatch<React.SetStateAction<UserContext['user']>>;
  company: CompanyModel | undefined | null;
  updateUserContext: () => void;
  login: () => Promise<void>;
  logout: () => void;
  loginWithGoogle: () => void;
  loginWithOpendId: () => void;
  loginWithMicrosoft: () => void;
  loginWithEmail: (params: {
    email: string;
    password: string;
  }) => Promise<void>;
  acceptTerms: () => void;
};

export const UserContext = React.createContext<UserContext>({
  userId: undefined,
  user: undefined,
  company: undefined,
  manager: undefined,
  setUser: () => {},
  updateUserContext: () => {},
  login: () => {
    return new Promise(() => {});
  },
  logout: () => {},
  loginWithGoogle: () => {},
  loginWithMicrosoft: () => {},
  loginWithOpendId: () => {},
  loginWithEmail: () =>
    new Promise(() => {
      console.log('this should not be executing');
    }),
  acceptTerms: () => {},
});

export const UserContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserContext['user']>();
  const [company, setCompany] = useState<UserContext['company']>();
  const [userId, setUserId] = useState<UserContext['userId']>();
  const [manager, setManager] = useState<UserContext['manager']>();
  const [newTerms, setNewTerms] = useState<boolean>(false);

  const isFinishRegistration = useRouteMatch({
    path: '/finishRegistration/:inviteId',
  });
  const isLoginPage = useRouteMatch({ path: '/', exact: true });
  const isPasswordRecovery = useRouteMatch({
    path: '/recuperarSenha/:passwordRecoveryId',
    exact: true,
  });

  const logout = () => {
    logoutCookie();
    logoutApi().finally(() => {
      setUser(null);
      setUserId(null);
      history.push('/');
    });
  };

  const login = async () => {
    try {
      setUser(undefined);
      const { userId, manager } = await whoAmI();
      const user = await getUser(userId);
      setUser(user);
      setUserId(userId);
      setManager(manager);
    } catch (error) {
      // console.log('Invalid session');
      // logout();
    }
  };

  const acceptTerms = () => {
    setNewTerms(false);
  };

  const loginWithEmail = async (params: {
    email: string;
    password: string;
  }) => {
    setUser(undefined);
    try {
      const { domain, exp, token, newTerms } = await userAuthenticate(params);
      setNewTerms(newTerms);
      loginCookie(token, exp, domain);
      login();
    } catch (error) {
      setUser(null);
    }
  };

  const loginWithGoogle = async () => {
    setUser(undefined);
    try {
      const credential = await ssoWithGoogle();
      if (credential == null) return 'deu ruim';
      const idToken = await getIdToken();
      const { domain, exp, token, newTerms } = await userAuthWithIdToken(
        idToken || ''
      );
      setNewTerms(newTerms);
      loginCookie(token, exp, domain);
      login();
    } catch (error) {
      toast.error('Não foi possivel logar com Google');
      setUser(null);
    }
  };

  const loginWithMicrosoft = async () => {
    setUser(undefined);
    try {
      const credential = await ssoWithMicrosoft();
      if (credential == null) return 'deu ruim';
      const idToken = await getIdToken();
      const { domain, exp, token, newTerms } = await userAuthWithIdToken(
        idToken || ''
      );
      setNewTerms(newTerms);
      loginCookie(token, exp, domain);
      login();
    } catch (error) {
      toast.error('Não foi possivel logar com Microsoft');
      setUser(null);
    }
  };

  const loginWithOpendId = async () => {
    setUser(undefined);
    try {
      const credential = await ssoWithOidc();
      console.log(credential);
      if (credential == null) return 'deu ruim';
      const idToken = await getIdToken();
      const { domain, exp, token, newTerms } = await userAuthWithIdToken(
        idToken || ''
      );
      setNewTerms(newTerms);
      loginCookie(token, exp, domain);
      login();
    } catch (error) {
      console.log(error);
      toast.error('Não foi possivel logar com SSO');
      setUser(null);
    }
  };

  const updateUserContext = () => {
    if (!userId) {
      setUser(null);
      history.replace('/');
    } else {
      getUser(userId).then(setUser);
    }
  };

  useEffect(() => {
    if (user?.companyId == null) {
      return;
    }
    getCompany(user.companyId).then(setCompany);
  }, [user?.companyId]);

  // decision making with user id
  useEffect(() => {
    if (isFinishRegistration) return;
    if (isPasswordRecovery) return;
    if (isLoginPage && userId && user) {
      if (newTerms) {
        return history.replace('/termsOfUse');
      }
      return history.replace('/colaborador/reconhecimento');
    }

    if (userId === undefined) return;

    if (userId === null) {
      if (isLoginPage) return;
      return history.replace('/');
    }

    if (user === undefined || user === null) {
      getUser(userId).then(setUser);
    }
  }, [
    user,
    isFinishRegistration,
    isLoginPage,
    isPasswordRecovery,
    userId,
    newTerms,
  ]);

  // setting userId
  useEffect(() => {
    if (isFinishRegistration) return;
    if (isPasswordRecovery) return;
    // if (isLoginPage) return;

    if (userId !== undefined) return;

    whoAmI()
      .then(({ userId, manager }) => {
        setUserId(userId);
        setManager(manager);
      })
      .catch(() => {
        logoutCookie();
        setUserId(null);
        setUser(null);
        setManager(undefined);
      });
  }, [isFinishRegistration, isLoginPage, isPasswordRecovery, userId]);

  return (
    <UserContext.Provider
      value={{
        user,
        userId,
        setUser,
        company,
        updateUserContext,
        login,
        logout,
        manager,
        loginWithGoogle,
        loginWithOpendId,
        loginWithMicrosoft,
        loginWithEmail,
        acceptTerms,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
