import React, { useContext } from 'react';
import { ReactComponent as MenuIcon } from '../assets/vectors/menu.svg';
import { ReactComponent as LogoIcon } from '../assets/vectors/logo.svg';
import { ReactComponent as AlternativeLogoIcon } from '../assets/vectors/logo-alternative.svg';
import { Wallets } from './Wallet';
import { UserContext } from './Context/UserContext';
import { Link } from 'react-router-dom';
import Tag from './Tag';
import GhostProfilePicture from './Ghost/GhostProfilePicture';
import { LanguageSwitcher } from './LanguageSwitcher';
import { i18n } from 'utils/i18n/index';
export interface NavbarProps {
  alternative?: boolean;
  hDivider?: boolean;
  vDivider?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ alternative, vDivider, hDivider }) => {
  const { user } = useContext(UserContext);

  const textColor = alternative ? 'text-white' : 'text-darker-90';
  const BgColor = alternative ? 'bg-primary-darker' : 'bg-white';
  const dividers = `${vDivider && 'divide-solid divide-x divide-opacity-50'} ${
    hDivider && 'border-b'
  }`;

  return (
    <nav
      className={`${BgColor} ${textColor} ${dividers} lg:grid lg:grid-cols-3 pt-2 pb-2 mb-2 sticky top-0 z-40 shadow`}
    >
      <div className="flex justify-between items-center col-span-3 lg:col-span-2 px-3">
        <Link
          to="/colaborador/reconhecimento"
          className="flex items-center gap-3"
        >
          {alternative ? <AlternativeLogoIcon /> : <LogoIcon />}
          <p className="hidden lg:inline-block text-3xl">meritu</p>
        </Link>
        <div className="flex gap-8 text-sm">
          {alternative ? <LanguageSwitcher /> : ''}
          <Link
            to="/colaborador"
            className="flex gap-2 justify-center items-center cursor-pointer"
          >
            { alternative ? '' :<MenuIcon className="stroke-current" />}
            { alternative ? '': <p className="inline-block">Menu</p>}
          </Link>
          {/* <NotificationIcon className="stroke-current" /> */}
        </div>
      </div>
     

      <div className="flex flex-1 justify-center mt-2 lg:mt-0 lg:justify-between items-center mx-4">
        <div className="flex gap-5 items-center">      
          <div className="flex items-baseline">
            <p>{i18n.t('toBeDistributed')} </p>
            <Tag alternative className="ml-1">
              {user?.meritMoney}
            </Tag>
          </div>
          <Wallets/>
        </div>

        <div className="hidden lg:flex items-center gap-3">  
          <Link to="/colaborador/perfil">
            <div className="w-9">
              <GhostProfilePicture
                src={user?.img}
                className=""
                name={user?.displayName}
                alt=""
              />
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
