import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { listCompanyFeed, listFeedResponse } from 'utils/api/feed';
import { i18n } from 'utils/i18n/index';
import RecognizeItem from './RecognizeItem';

interface RecognizeListProps {
  // recognitions: listFeedResponse | undefined;
  reRender: boolean;
  setRerenderer: (status: boolean) => void;
  companyId: string | undefined;
  filters: Record<any, any>;
}

const RecognizeList: React.FC<RecognizeListProps> = ({
  reRender,
  setRerenderer,
  companyId,
  filters,
}) => {
  // counter for limit
  const limit = 20;
  // counter for offset
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [recognitions, setRecognitions] = useState<
    listFeedResponse | undefined
  >();

  useEffect(() => {
    if (companyId === undefined) {
      return;
    }
    if (reRender) {
      listCompanyFeed(companyId, { offset: 0, limit, ...filters })
        .then((newRecognitions) => {
          setRerenderer(false);
          setRecognitions(newRecognitions);
          setOffset(limit);

          if (newRecognitions.length <= 0) return setHasMore(false);
          setHasMore(true);
        })
        .finally(() => setRerenderer(false));
    }
  }, [reRender, companyId, offset, setRerenderer, filters]);

  const fetchData = () => {
    if (companyId === undefined || recognitions === undefined) {
      return;
    }
    listCompanyFeed(companyId, { offset, limit, ...filters }).then(
      (newRecognitions) => {
        if (newRecognitions.length <= 0) return setHasMore(false);
        setRecognitions([...recognitions, ...newRecognitions]);
        setOffset(offset + limit);
        setHasMore(true);
      }
    );
  };

  const refresh = () => {
    if (companyId === undefined) {
      return;
    }
    listCompanyFeed(companyId, { offset, limit, ...filters }).then(
      (newRecognitions) => {
        if (newRecognitions.length <= 0) return setHasMore(false);
        setRecognitions(newRecognitions);
        setOffset(limit);
        setHasMore(true);
      }
    );
  };

  return (
    <InfiniteScroll
      dataLength={recognitions?.length || 0} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
      loader={
        <div className="w-full text-center mt-3">
          <h4>{i18n.t('loading')}</h4>
        </div>
      }
      endMessage={
        <p className="text-center text-black mt-3">
          {i18n.t('youSawEverything')}
        </p>
      }
      // below props only if you need pull down functionality
      refreshFunction={refresh}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
      pullDownToRefreshContent={
        <h3 style={{ textAlign: 'center' }}>
          &#8593; {i18n.t('releaseToUpdate')}
        </h3>
      }
      releaseToRefreshContent={
        <h3 style={{ textAlign: 'center' }}>&#8593; Solte para atualizar</h3>
      }
    >
      {recognitions?.map((recognition) => {
        return (
          <RecognizeItem key={recognition.id} recognitionObject={recognition} />
        );
      })}
    </InfiniteScroll>
  );
};

export default RecognizeList;
