import { initializeApp } from 'firebase/app';

import { GoogleAuthProvider, OAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';


const firebaseConfig = {
  apiKey: 'AIzaSyA-jo5CSpeJF22B-rADo2CLN5Fo8iTD3kQ',
  authDomain: 'meritu-dotco2.firebaseapp.com',
  databaseURL: 'https://meritu-dotco2.firebaseio.com',
  projectId: 'meritu-dotco2',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

const microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({ prompt: 'select_account' });

const oidcProvider = new OAuthProvider('oidc.gerdau');
microsoftProvider.setCustomParameters({ prompt: 'select_account', login_hint: 'user@example.com' });

export const ssoWithGoogle = () =>
  signInWithPopup(auth, googleProvider).then((result) => {
    return GoogleAuthProvider.credentialFromResult(result);
  });


export const ssoWithMicrosoft = () =>
  signInWithPopup(auth, microsoftProvider).then((result) => {
    return GoogleAuthProvider.credentialFromResult(result);
  });

export const ssoWithOidc = () =>
  signInWithPopup(auth, oidcProvider).then((result) => {
    console.log('Login with OIDC')
    return GoogleAuthProvider.credentialFromResult(result);
  });
export const getIdToken = () => {
  console.log(auth.currentUser)
  return auth.currentUser?.getIdToken()
};
