import { ErrorMessage, Field, FieldProps } from 'formik';
import React from 'react';

export type SingleSpreadedSelectOptions = {
  label: string;
  value: string;
}[];

interface SingleSpreadedSelectProps {
  name: string;
  options: SingleSpreadedSelectOptions;
  className?: string;
}
const SingleSpreadedSelect: React.FC<SingleSpreadedSelectProps> = (props) => {
  return <Field component={SingleSpreadedSelectChild} {...props} />;
};

interface SingleSpreadedSelectChildProps
  extends FieldProps,
    SingleSpreadedSelectProps {}

const SingleSpreadedSelectChild: React.FC<SingleSpreadedSelectChildProps> = ({
  options,
  className,
  field,
  form,
  meta,
}) => {
  return (
    <div className="relative">
      <div className={`${className} relative`}>
        {options.map((option) => (
          <p
            key={option.value}
            className={`${
              field.value === option.value
                ? 'bg-primary-darker text-white'
                : 'bg-neutral text-darker-50'
            } px-4 py-1 rounded-full cursor-pointer`}
            onClick={() => form.setFieldValue(field.name, option.value)}
          >
            {option.label}
          </p>
        ))}
      </div>
      <p className="text-xs text-red absolute">
        <ErrorMessage name={field.name} />
      </p>
    </div>
  );
};

export default SingleSpreadedSelect;
