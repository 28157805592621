import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';

import { ReactComponent as LogoIcon } from 'assets/vectors/logo.svg';
import { ReactComponent as GiftIcon } from 'assets/vectors/gift.svg';
import img from 'assets/images/login.png';

import { logoutCookie } from 'utils/jwt';
import history from 'utils/history';

import AutoMargin from 'Components/AutoMargin';
import InputText from 'Components/Formik/InputText';
import { Link } from 'react-router-dom';
import {
  finishPasswordRecoveryApi,
  isValidPasswordRecoveryApi,
} from 'utils/api/passwordRecovery';
import { toast } from 'react-toastify';
import { FinishPasswordRecoverySchema } from 'utils/validators/passwordRecovery';
import { i18n } from 'utils/i18n/index';
export interface PasswordRecoveryProps {}
const PasswordRecovery: React.FC<PasswordRecoveryProps> = (props) => {
  let { passwordRecoveryId } = useParams<{ passwordRecoveryId: string }>();

  useEffect(() => {
    logoutCookie();
  }, []);

  useEffect(() => {
    isValidPasswordRecoveryApi(passwordRecoveryId).then(({ status }) => {
      if (status !== 'pending') {
        toast.info(`${i18n.t("passwordRequestExpired")}`);
        history.push('/');
      }
    });
  }, [passwordRecoveryId]);

  return (
    <div className="flex justify-between items-stretch min-h-screen">
      <div className="hidden lg:flex flex-col justify-around p-16 min-h-full bg-secondary w-7/12 rounded-r-xl">
        <p className="font-normal text-7xl text-white">
          {i18n.t("boostText")}
          <br />{i18n.t("theValue")} <br />
          {i18n.t("ofConnections")}
        </p>
        <div className="flex justify-between">
          <div className="rounded-full w-1/4">
            <img src={img} alt="" className="rounded-full w-full h-auto" />
          </div>
          <div className=" relative transform translate-y-1/2 w-1/4">
            <div className="absolute transform -translate-y-1/2 rounded-full bg-white w-full h-full" />
            <div className="relative flex justify-center items-center w-full pb-full">
              <GiftIcon className="absolute stroke-current text-primary-darker w-1/2" />
              <div className="absolute transform -translate-x-1/2 border-r-0 border-solid border-white border w-screen h-full" />
              <div className="absolute transform translate--1/2 -translate-y-1/2 border-b-0 border-solid border-white border w-full h-screen" />
            </div>
          </div>
        </div>
      </div>
      <main className="flex justify-center items-center flex-grow">
        <AutoMargin className="w-full px-4 lg:w-96">
          <div className="flex gap-3 items-center font-bold text-4xl text-black mb-28">
            <LogoIcon />
            meritu
          </div>
          <div className="font-medium text-xl text-black">
            {i18n.t("recoverPassword")}
          </div>
          <div className="mb-16">
           {i18n.t("typeNewPassword")}
          </div>
          <Formik
            initialValues={{ password: '', confirm: '' }}
            validationSchema={FinishPasswordRecoverySchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              const promise = finishPasswordRecoveryApi(passwordRecoveryId, {
                password: values.password,
              });
              toast.promise(promise, {
                pending: `${i18n.t("registerNewPassword")}`,
                success: {
                  render() {
                    actions.setSubmitting(false);
                    history.push('/');
                    return `${i18n.t("passwordRegisterSuccessfully")}`;
                  },
                },
                error: {
                  render({ data }) {
                    actions.setSubmitting(false);
                    return `${i18n.t("error")}`;
                  },
                },
              });
            }}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col">
                <label className="flex flex-col text-xs w-full mt-6">
                  {i18n.t("password")}
                  <InputText
                    name="password"
                    autoComplete='off'
                    password
                    placeholder={i18n.t("yourNewPassword")}
                  />
                </label>
                <label className="flex flex-col text-xs w-full mt-6">
                  {i18n.t("confirmation")}
                  <InputText
                    name="confirm"
                    autoComplete='off'
                    password
                    className="mt-2"
                    placeholder= {i18n.t("repeatYourNewPassword")}
                  />
                </label>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="py-2 bg-primary-darker text-white box-border rounded-full mt-10 disabled:opacity-30"
                >
                  {i18n.t("registerPassword")}
                </button>
                <Link
                  to="/"
                  className="py-2 bg-white border text-primary-darker box-border rounded-full mt-3 text-center"
                >
                  {i18n.t("cancel")}
                </Link>
              </Form>
            )}
          </Formik>
        </AutoMargin>
      </main>
    </div>
  );
};

export default PasswordRecovery;
