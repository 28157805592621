import React, { useContext, useEffect, useState } from 'react';
import { addComment, addLike, removeLike } from 'utils/api/feed';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as HeartIcon } from '../assets/vectors/heart.svg';
import { ReactComponent as MessageIcon } from '../assets/vectors/message-circle.svg';
import { ReactComponent as SendIcon } from '../assets/vectors/send.svg';
import { UserContext } from './Context/UserContext';
import { i18n } from 'utils/i18n/index';
import RecognizeCanvas from 'Pages/Colaborador/Recognize/RecognizeCavas';
import { getFeedResponse } from 'utils/api/feed';

interface LikeAndCommentProps {
  likes: string[];
  updateLike?: () => void;
  updateComments: (comment: string) => void;
  contentId: string;
  date?: Date;
  className?: string;
  recognition: getFeedResponse;
  alwaysShowComment?: boolean;
}

const LikeAndComment: React.FC<LikeAndCommentProps> = ({
  likes,
  className,
  date,
  contentId,
  updateComments,
  recognition,
  alwaysShowComment = false,
}) => {
  const isLarge = useMediaQuery({ minWidth: 1024 });
  const { user } = useContext(UserContext);
  const [showInput, setShowInput] = useState(alwaysShowComment);
  const [isSubmitingComment, setIsSubmitingComment] = useState(false);
  const [isSubmitingLike, setIsSubmitingLike] = useState(false);
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');
  const [componentLikes, setComponentLikes] = useState(likes);
  const [isLiked, setIsLiked] = useState(
    likes.includes(user?.id || '') || false
  );

  const dateSize = isLarge ? 'medium' : 'short';

  const handleLike = (e: any) => {
    setIsSubmitingLike(true);
    e.preventDefault();
    isLiked
      ? removeLike(contentId)
          .then(async () => {
            setIsLiked(false);
            setIsSubmitingLike(false);
            const aux = [...componentLikes];
            aux.pop();
            setComponentLikes(aux);
          })
          .catch((e: Error) => {
            // setError(e.message);
            setIsSubmitingLike(false);
          })
      : addLike(contentId)
          .then(async () => {
            setIsLiked(true);
            setIsSubmitingLike(false);
            setComponentLikes([...componentLikes, user?.id || '']);
          })
          .catch((e: Error) => {
            // setError(e.message);
            setIsSubmitingLike(false);
          });
  };

  const handleSubmit = (e: any) => {
    setIsSubmitingComment(true);
    e.preventDefault();
    addComment(contentId, comment)
      .then(async () => {
        setComment('');
        setShowInput(false);
        updateComments(comment);
        setIsSubmitingComment(false);
      })
      .catch((e: Error) => {
        setError(e.message);
        setIsSubmitingComment(false);
      });
  };

  return (
    <div className={className}>
      <div className="flex flex-row justify-between">
        <div className="flex text-sm items-center text-center">
          <button
            className="flex items-center hover:text-primary-darker text-sm lg:text-base"
            onClick={handleLike}
            disabled={isSubmitingLike}
          >
            <HeartIcon
              className={`mr-1 w-5 ${isLiked && 'text-secondary fill-current'}`}
            />
            <p className="hidden md:block">
              {i18n.t('enjoy')}
              {componentLikes.length > 0 && ` (${componentLikes.length})`}
            </p>
          </button>
          {!alwaysShowComment && (
            <button
              className="flex items-center hover:text-primary-darker text-sm lg:text-base"
              onClick={() => setShowInput(!showInput)}
            >
              <MessageIcon className="ml-3 mr-1 w-5" />
              <p className="hidden md:block">
                {showInput ? `${i18n.t('hide')}` : `${i18n.t('comment')}`}
              </p>
            </button>
          )}
          <div className="flex ml-2">
            <RecognizeCanvas
              key={recognition.id}
              recognitionObject={recognition}
            />
          </div>
        </div>

        <p className="text-sm">
          {date?.toLocaleDateString(i18n.t('dateLocaleString'), {
            dateStyle: dateSize,
          })}
        </p>
      </div>
      {showInput && (
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between bg-gray w-full px-4 py-3 rounded-input mt-5 border border-primary-darker border-opacity-0 focus-within:border-opacity-100">
            <input
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-full outline-none bg-gray text-sm lg:text-base"
              placeholder={i18n.t('writeComment')}
            />
            <button
              disabled={isSubmitingComment}
              className="hover:text-primary-darker disabled:opacity-30"
            >
              <SendIcon />
            </button>
          </div>
          <p className="text-xs text-red absolute font-bold">{error}</p>
        </form>
      )}
    </div>
  );
};

export default LikeAndComment;
