import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';

import profileDefault from '../../../../assets/images/default-profile.png';
import { UserModel } from '../../../../utils/api/users';

import { ReactComponent as ChatIcon } from '../../../../assets/vectors/color-chat.svg';
// import { ReactComponent as GiftIcon } from '../../../../assets/vectors/color-gift.svg';
// import { ReactComponent as MedalIcon } from '../../../../assets/vectors/color-medal.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/vectors/color-phone.svg';

import DatePicker from 'Components/Formik/DatePicker';
import InputText from 'Components/Formik/InputText';

import { InitialValues } from '..';
import InputTextArea from 'Components/Formik/InputTextArea';

interface PasswordProps {
  user: Pick<UserModel, 'name'>;
  setShowImagePicker: (show: boolean) => void;
}

const Password: React.FC<PasswordProps> = ({ user, setShowImagePicker }) => {
  const { values } = useFormikContext<InitialValues>();
  return (
    <div className="password text-sm lg:text-base ">
      <main className="flex justify-center items-center lg:grid lg:grid-cols-2 gap-4 my-4">
        <div className="hidden lg:inline-block font-normal self-center">
          <span className="text-4xl">Seja muito bem vindo(a), </span>
          {/* <span className="text-4xl font-semibold">{user.name}</span> */}
          <div className="text-xl font-extralight">
            Para prosseguir, complete o seu cadastro adicionando
            <br />
            uma foto e outras informações.
          </div>
          {/* <div className="flex gap-2 items-center">
            <MedalIcon />
            <p className="">Ganhe um troféu de Perfil Completo</p>
          </div> */}
          {/* <hr className="w-24 my-3 border-lighter-70" /> */}
          <div className="flex gap-2 items-center mt-16">
            <ChatIcon />
            <p>Permita que seus colegas te reconheçam</p>
          </div>
          <hr className="w-24 my-3 border-lighter-70" />
          <div className="flex gap-2 items-center">
            <PhoneIcon />
            <p>Tenha uma senha mais segura</p>
          </div>
          {/* <hr className="w-24 my-3 border-lighter-70" /> */}
          {/* <div className="flex gap-2 items-center">
            <GiftIcon />
            <p>Garanta que os produtos merecidos cheguem à sua casa</p>
          </div> */}
        </div>
        <div className="min-h-full flex flex-col items-center gap-3 rounded-xl px-5 py-5 text-gray-500 shadow overflow-x-auto">
          <div className="flex flex-col items-center lg:flex-row bg-gray-200 rounded-xl text-gray-500 p-4 bg-neutral min-w-full">
            <img
              src={values.img ? values.img : profileDefault}
              alt=""
              style={{ width: '90px', height: '90px' }}
              className="object-contain border border-lighter-70 rounded-full bg-gray-400"
            />
            <div className="flex flex-col justify-center items-center lg:items-start lg:ml-6">
              <p className="font-semibold">{values.displayName || 'Seu apelido'}</p>
              {/* <p className="font-light text-black text-sm">
                {values.jobTitle || 'Sem cargo'}
              </p> */}
              <div className="font-light text-xs flex flex-col lg:flex-row items-center gap-2">
                Data de nascimento:
                <DatePicker
                  name="birthday"
                  className="rounded-input border border-darker-90 px-2"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center lg:grid lg:grid-cols-2 justify-around w-full gap-6 mt-3">
            <label className="flex flex-col text-xs w-full">
              Como você quer ser chamado?
              <InputText name="displayName" placeholder="Digite seu nome ou um apelido" />
            </label>
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowImagePicker(true);
              }}
              className="w-full h-full py-1 border rounded-input border-lighter-70"
            >
              Subir foto de perfil
            </button>
            {/* <label className="flex flex-col text-xs w-full">
              Qual o seu cargo?
              <InputText name="jobTitle" placeholder="Digite novamente" />
            </label> */}

            <label className="col-span-2 flex flex-col text-xs w-full">
              Crie uma bio
              <InputTextArea
                className="h-20"
                name="bio"
                placeholder="Fale um pouco sobre você"
              />
            </label>

            <label className="flex flex-col text-xs w-full">
              Crie uma nova senha
              <InputText
                name="password"
                password
                placeholder="Digite a senha"
                autoComplete='off'
              />
            </label>
            <label className="flex flex-col text-xs w-full">
              Repita a senha
              <InputText
                name="confirm"
                password
                placeholder="Digite novamente"
                autoComplete='off'
              />
            </label>

            <label className="flex flex-col text-xs w-full">
              Email secundário (*opcional)
              <InputText
                name="backupEmail"
                placeholder="Digite seu email secundário"
              />
            </label>
            <label className="flex flex-col text-xs w-full">
              Confirme seu email secundário (*opcional)
              <InputText
                name="confirmBackupEmail"
                placeholder="Digite novamente"
              />
            </label>

            <label className="flex flex-col text-xs w-full">
              Celular (*opcional)
              <InputText name="mobile" placeholder="Digite o celular" />
            </label>
            {/* <label className="flex flex-col text-xs w-full">
              CEP
              <InputText name="postalCode" placeholder="Digite o cep" />
            </label>

            <label className="flex flex-col text-xs w-full">
              Rua
              <InputText name="street" placeholder="Digite a rua" />
            </label>
            <label className="flex flex-col text-xs w-full">
              Número e complemento
              <InputText
                name="numberAndComplement"
                placeholder="Digite numero e complemento"
              />
            </label>

            <label className="flex flex-col text-xs w-full">
              Bairro
              <InputText name="district" placeholder="Digite o bairro" />
            </label>
            <label className="flex flex-col text-xs w-full">
              Cidade
              <InputText name="city" placeholder="Digite a cidade" />
            </label>

            <label className="flex flex-col text-xs w-full">
              Estado
              <InputText name="state" placeholder="Selecione" />
            </label> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Password;
