import * as yup from 'yup'
import {ptShort} from 'yup-locale-pt'

yup.setLocale(ptShort)
export const recognizeSchema = yup.object().shape({
    selectedUser: yup.array().of(yup.string().min(1)).required(),
    meritMoney: yup.number(),
    meritus: yup.number().typeError('Deve ser um Numero').positive().integer().required(),
    totalMeritus: yup.number().positive().max(yup.ref('meritMoney'), 'Saldo insuficiente').integer(),
    checkedValue: yup.string().required(),
    message: yup.string().trim().ensure().min(3).max(500),
  }).noUnknown(true)