import { getFeedResponse } from 'utils/api/feed';
import React, { useState } from 'react';
import background from 'assets/images/background-share.jpg';
import { ReactComponent as Share } from 'assets/vectors/share.svg';
import { ReactComponent as Download } from 'assets/vectors/download.svg';
import heart from 'assets/vectors/heart.svg';
import message from 'assets/vectors/message-circle.svg';
import { toast } from 'react-toastify';
import { i18n } from 'utils/i18n';

interface RecognizeItemProps {
  recognitionObject: getFeedResponse;
}

const RecognizeCanvas: React.FC<RecognizeItemProps> = ({
  recognitionObject,
}) => {
  const [recognition] = useState(recognitionObject);

  const wrapText = (
    ctx: CanvasRenderingContext2D,
    text: any,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number
  ) => {
    const words = text.split(' ');
    let line = '';
    for (const [index, w] of words.entries()) {
      const testLine = line + w + ' ';
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && index > 0) {
        ctx.fillText(line, x, y);
        line = w + ' ';
        y += lineHeight;
      } else {
        line = testLine;
      }
    }
    ctx.fillText(line, x, y);
  };

  const createImage = (url: string) => {
    const image = new Image();
    image.src = url;
    image.crossOrigin = 'anonymous';
    return image;
  };

  const download = (filename: string, url: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const getYear = (year: number) => {
    switch (year) {
      case 1:
        return 'jan.';
      case 2:
        return 'fev.';
      case 3:
        return 'mar.';
      case 4:
        return 'abr.';
      case 5:
        return 'mai.';
      case 6:
        return 'jun.';
      case 7:
        return 'jul.';
      case 8:
        return 'ago.';
      case 9:
        return 'set.';
      case 10:
        return 'out.';
      case 11:
        return 'nov.';
      case 12:
        return 'dez.';
    }
  };

  const handleShare = (shareTarget: any) => {
    const promise = shareRecognition(shareTarget)

    toast.promise(promise,{
      pending: i18n.t('generatingImageToShare'),
      error: i18n.t('somethingWrong'),
      success: i18n.t('shareCompleted')
    })
  }

  const shareRecognition = async (shareTarget: any) => {
    const divElement = shareTarget.closest('#recognitionCanvas');
    const canvasElement = divElement.children[0] as HTMLCanvasElement;
    const c = canvasElement?.getContext('2d');

    const canvasProfilePictureFromElement = document.getElementById(
      'resizeProfileImageFrom'
    ) as HTMLCanvasElement;
    const tCtxFrom = canvasProfilePictureFromElement?.getContext('2d');

    const canvasProfilePictureToElement = document.getElementById(
      'resizeProfileImageTo'
    ) as HTMLCanvasElement;
    const tCtxTo = canvasProfilePictureToElement?.getContext('2d');

    const recognizeMessage = `${recognition.to?.displayName} foi reconhecido(a) por ${recognition.additionalInformation.companyValue}`;
    const missionMessage = `${recognition.to?.displayName} completou a missão: ${recognition.additionalInformation.message}`;
    const recognizeAdditionalInformation = `"${recognition.additionalInformation.message}" -${recognition.from?.displayName}`;
    const missionAdditionalInformation = ` " Colaborador ${
      recognition.to?.displayName
    } completou a missão ${
      recognition.additionalInformation.message
    } que tem por objetivo reforçar os valores ${recognition.additionalInformation.companyValues?.map(
      (values) => values
    )} da empresa "`;
    const background = document.getElementById(
      'background'
    ) as HTMLCanvasElement;
    const heart = document.getElementById('heart') as HTMLCanvasElement;
    const message = document.getElementById('message') as HTMLCanvasElement;

    const dateDay = new Date(recognition.createdAt).getDate();
    let dateMonthNumber = new Date(recognition.createdAt).getMonth() + 1;
    const dateMonth = getYear(dateMonthNumber);
    const dateYear = new Date(recognition.createdAt).getFullYear();
    const dateString = `${dateDay} de ${dateMonth} de ${dateYear}`;

    if (c !== null && c !== undefined) {
      if (background !== null) c.drawImage(background, 0, 0);

      c.font = '20px BRHendrix';
      c.fillStyle = '#000000';

      recognition.transactionType !== 'mission'
        ? wrapText(c, recognizeMessage, 330, 200, 1070, 40)
        : wrapText(c, missionMessage, 330, 200, 1070, 40);

      recognition.transactionType !== 'mission'
        ? wrapText(c, recognizeAdditionalInformation, 330, 260, 720, 32)
        : wrapText(c, missionAdditionalInformation, 330, 260, 720, 32);

      if (heart !== null) c.drawImage(heart, 155, 520);
      c.fillText('Curtir', 183, 540);

      if (message !== null) c.drawImage(message, 255, 520);
      c.fillText('Comentar', 283, 540);

      if (recognition.createdAt) c.fillText(dateString, 910, 540);

      if (tCtxFrom !== null) {
        c.fillStyle = '#f3f6fc';
        c.beginPath();
        c.arc(195, 230, 40, 0, 2 * Math.PI, true);
        c.closePath();
        c.fill();
        c.font = '30px BRHendrix';
        c.fillStyle = '#000000';
        wrapText(c, recognition.from?.displayName[0], 182, 240, 30, 40);
        if (recognition.from?.img) {
          const fromImage = createImage(recognition.from?.img);
          await fromImage
            .decode()
            .then(() => {
              tCtxFrom.drawImage(fromImage, 0, 0, 80, 80);
              let patternFrom = c.createPattern(
                canvasProfilePictureFromElement,
                'repeat'
              );

              if (patternFrom) {
                patternFrom.setTransform(new DOMMatrix([1, 0, 0, 1, 55, -10]));
                c.fillStyle = patternFrom;
                c.beginPath();
                c.arc(195, 230, 40, 0, 2 * Math.PI, true);
                c.closePath();
                c.fill();
              }
            })
            .catch(() => {
              console.error('Could not load the profile image');
            });
        }
      }

      if (tCtxTo !== null) {
        c.fillStyle = '#f3f6fc';
        c.strokeStyle = '#ffffff';
        c.lineWidth = 8;
        c.beginPath();
        c.arc(250, 230, 40, 0, 2 * Math.PI, true);
        c.stroke();
        c.closePath();
        c.fill();
        c.font = '30px BRHendrix';
        c.fillStyle = '#000000';
        wrapText(c, recognition.to?.displayName[0], 238, 240, 30, 40);
        if (recognition.to?.img) {
          const toImage = createImage(recognition.to?.img);
          await toImage
            .decode()
            .then(() => {
              tCtxTo.drawImage(toImage, 0, 0, 80, 80);
              let patternTo = c.createPattern(
                canvasProfilePictureToElement,
                'repeat'
              );

              if (patternTo) {
                patternTo.setTransform(new DOMMatrix([1, 0, 0, 1, 10, -10]));
                c.strokeStyle = '#ffffff';
                c.lineWidth = 8;
                c.fillStyle = patternTo;
                c.beginPath();
                c.arc(250, 230, 40, 0, 2 * Math.PI, true);
                c.stroke();
                c.closePath();
                c.fill();
              }
            })
            .catch(() => {
              console.error('Could not load the profile image');
            });
        }
      }
    }

    const canvasImageUrl = canvasElement.toDataURL();
    const blob = await (await fetch(canvasImageUrl)).blob();
    const filesArray: File[] = [
      new File([blob], 'reconhecimento.png', {
        type: blob.type,
        lastModified: new Date().getTime(),
      }),
    ];
    const shareData = {
      files: filesArray,
      title: 'meritu.co',
      text: 'Impulsionando o valor das conexões',
      url: 'https://meritu.co/',
    };

    const isMobile = window.matchMedia(
      'only screen and (max-width: 760px)'
    ).matches;

    try {
      if (isMobile) {
        await navigator.share(shareData as any);
      } else {
        download('reconhecimento.png', canvasImageUrl);
      }
    } catch (err: any) {
      console.error('Share failed', err.message);
    }
  };

  return (
    <div id="recognitionCanvas">
      <canvas
        id="canvasElement"
        width="1200"
        height="627"
        className="hidden"
      ></canvas>
      <canvas
        id="resizeProfileImageFrom"
        width="100"
        height="100"
        className="hidden"
      ></canvas>
      <canvas
        id="resizeProfileImageTo"
        width="100"
        height="100"
        className="hidden"
      ></canvas>
      <img src={background} alt="" className="hidden" id="background" />
      <img src={heart} alt="" className="hidden" id="heart" />
      <img src={message} alt="" className="hidden" id="message" />
      {/*  <div 
            onClick={() => shareRecognition(window.event?.target)}
            className ="flex justify-end">
                <img
                src= {share}
                alt=""
                className='w-5 mr-1'/>      
          </div>*/}
      <button
        className="flex items-center hover:text-primary-darker text-sm lg:text-base"
        onClick={() => handleShare(window.event?.target)}
      >
        <Share className="mr-1 w-5 md:hidden" />
        <p className="md:hidden">Compartilhar</p>

        <Download className="mr-1 w-5 hidden md:block" />
        <p className="hidden md:block">Download</p>
      </button>
    </div>
  );
};

export default RecognizeCanvas;
