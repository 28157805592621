import React, { Dispatch, SetStateAction } from 'react';

import AutoMargin from 'Components/AutoMargin';

import { ReactComponent as ArrowIcon } from 'assets/vectors/arrowWithLine.svg';
import { ReactComponent as DistributedIcon } from 'assets/vectors/distributedMeritus.svg';
import { ReactComponent as AvaliableMeritusIcon } from 'assets/vectors/avaliableMeritus.svg';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import { Link } from 'react-router-dom';
import Square from 'Components/Square';
import { isGetAccessor } from 'typescript';
import { i18n } from '../../../utils/i18n/index'
import { t } from 'i18next';

interface SidebarProps {
  setShowMakeRecognition: Dispatch<SetStateAction<boolean>>;
  className?: string
}

const Sidebar: React.FC<SidebarProps> = ({ setShowMakeRecognition, className }) => {
  const { user } = useContext(UserContext);
  return (
    <div className={className}>
      <AutoMargin className="w-full flex flex-col items-center">
        <div className="shadow rounded-xl px-7 py-5 mt-5 w-full">
          <div className="bg-neutral p-3 rounded-xl">
            {/* <div className="flex justify-between">
            <p className="text-black text-xl">Acompanhe</p>
            <Link to="/colaborador/perfil" className="text-primary-darker">
              Ver perfil
            </Link>
          </div> */}
            {/* <div className="flex gap-2 mt-5"></div> */}
            <div>
              <div className="flex items-center">
                <Square className="rounded bg-neutral-darker" size="w-1/6">
                  <AvaliableMeritusIcon className="w-3/5 h-auto" />
                </Square>
                <div className="flex flex-col p-5">
                  <p className="font-bold">{`${i18n.t("youHave")} ${user?.meritMoney} ${i18n.t("credits")}`}</p>
                  <p>{i18n.t('toRecognizeCollegues')}</p>
                </div>
              </div>
              <button
                className=" bg-primary-darker rounded px-5 py-2 text-white flex-grow w-full mt-2"
                onClick={() => setShowMakeRecognition(true)}
              >
                {i18n.t('recognizeColleague')}
              </button>

              {/* <div className="flex flex-col items-center rounded bg-neutral text-black p-5 w-full text-center">
              <div className="p-3 bg-neutral-darker rounded-input">
                <DistributedIcon />
              </div>
              <p className="text-lg mt-4">3.200</p>
              <p className="text-sm">Reconhecimentos entregues no mês</p>
            </div> */}
            </div>
          </div>
        </div>
        {/*<div className="mt-5 p-4 max-w-sm" >
            <img src="https://storage.googleapis.com/meritu-co-storage/banner/anuncioFeed.jpeg" alt="baanner patch notes" className="rounded" />
          </div>*/}
        {/* <div className="shadow rounded p-5 mt-7">
          <div className="flex items-center justify-between">
            <p className="text-black text-xl">Selos conquistados</p>
            <p className=" text-primary-darker text-sm">Ver Tudo</p>
          </div>
          <div className="flex flex-col gap-3 mt-5">
            <div className="flex gap-2 items-center rounded-input bg-gray p-4">
              <div className="w-1/6">
                <div className="rounded bg-neutral-darker w-full pb-full"></div>
              </div>
              <div>
                <p className="text-sm text-black">Perfil Completo</p>
                <p className="text-sm">O seu perfil está 100% completo!</p>
              </div>
            </div>
            <div className="flex gap-2 items-center rounded-input bg-gray p-4">
              <div className="w-1/6">
                <div className="rounded bg-neutral-darker w-full pb-full"></div>
              </div>
              <div>
                <p className="text-sm text-black">Perfil Completo</p>
                <p className="text-sm">O seu perfil está 100% completo!</p>
              </div>
            </div>
            <div className="flex gap-2 items-center rounded-input bg-gray p-4">
              <div className="w-1/6">
                <div className="rounded bg-neutral-darker w-full pb-full"></div>
              </div>
              <div>
                <p className="text-sm text-black">Perfil Completo</p>
                <p className="text-sm">O seu perfil está 100% completo!</p>
              </div>
            </div>
          </div>
        </div> */}
      </AutoMargin>
    </div>
  );
};

export default Sidebar;
