import { ErrorMessage, Field, FieldProps } from 'formik';
import Select, { OptionsType, OptionTypeBase } from 'react-select';
import React from 'react';
import { Option } from 'react-select/src/filters';

export type SelectOptions = OptionsType<OptionTypeBase>;

interface SelectFieldProps {
  name: string;
  options: SelectOptions;
  className?: string;
  placeholder?: string;
  withoutBorder?: boolean;
  onChangeCallback?: (value: string) => void;
  isDisabled?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = (props) => {
  return <Field component={SelectChild} {...props} />;
};

const selectStyle = {
  control: (base: {}) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    indicatorSeparator: { display: 'none' },
  }),
};

interface SelectChildProps extends FieldProps, SelectFieldProps {}

const SelectChild: React.FC<SelectChildProps> = ({
  field,
  form,
  meta,
  options,
  className,
  placeholder,
  withoutBorder,
  onChangeCallback,
  isDisabled,
}) => {
  const handleValue = () => {
    const value = options.find((option) => option.value === field.value);
    return value ? value : null;
  };
  return (
    <div className={`${className} relative bg-white`}>
      <Select
        isDisabled={isDisabled}
        options={options}
        name={field.name}
        className={`px-2 h-10 ${
          withoutBorder ?? 'border border-lighter-70'
        } rounded-input focus:outline-none`}
        styles={selectStyle}
        components={{
          IndicatorSeparator: () => null,
        }}
        value={handleValue()}
        onChange={(option: Option) => {
          form.setFieldValue(field.name, option.value);
          onChangeCallback && onChangeCallback(option.value);
        }}
        onBlur={field.onBlur}
        placeholder={placeholder}
      />
      <p className="text-xs text-red absolute">
        <ErrorMessage name={field.name} />
      </p>
    </div>
  );
};

export default SelectField;
