import Linkify from 'linkify-react';
import React, { useState } from 'react';
import { i18n } from 'utils/i18n/index';

interface ReadMoreProps {
  text: string;
  numSplit: number;
  className?: string;
}

const ReadMore: React.FC<ReadMoreProps> = ({ className, text, numSplit }) => {
  // divide text Read more
  const [isExpanded, setIsExpanded] = useState(false);
  const baseDesc = text.slice(0, numSplit);
  const extraDesc = text.slice(numSplit);
  return (
    <Linkify
      options={{
        className: 'text-primary hover:text-priamary-darker',
        target: '_blank',
        truncate: 35,
      }}
    >
      <p className={className}>
        {baseDesc}
        {!extraDesc ? (
          ''
        ) : isExpanded ? (
          <>
            <span>{extraDesc}</span>
            <br />
            <span
              className="cursor-pointer text-primary-darker font-medium"
              onClick={() => setIsExpanded(false)}
            >
              {i18n.t('readLess')}
            </span>
          </>
        ) : (
          <>
            <span>...</span>
            <span
              className="cursor-pointer text-primary-darker font-medium"
              onClick={() => setIsExpanded(true)}
            >
              {i18n.t('readMore')}
            </span>
          </>
        )}
      </p>
    </Linkify>
  );
};

export default ReadMore;
