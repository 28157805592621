import api from './axios';
import { CompanyModel } from './company';
import { UserModel } from './users';

export type UserInviteModel = {
  id: string;
  companyId: CompanyModel['id'];
  name: UserModel['name'];
  createdAt: Date;
  status: 'expired' | 'pending' | 'complete';
}

export const getInvite = async (userId: UserModel['id']) => {
  const { data } = await api.get<UserInviteModel>(`invite/${userId}`, {});
  return data;
};