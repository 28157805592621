// import { getToken } from 'utils/jwt';
import api from './axios';
import { CompanyModel } from './company';
import { UserModel } from './users';
import { WalletModel } from './wallet';

export type MissionModel = {
  id: string;
  company: CompanyModel['id'];
  name: string;
  description: string;
  meritus: number;
  status: 'done' | 'running';
  approveType: 'simple' | 'proof';
  groups: Array<string>;
  startsAt: Date | null;
  endsAt: Date | null;
  acceptedBy: Array<UserModel['id']>;
  companyValues: string[];
  doneBy: Array<{
    userId: UserModel['id'];
    prove: string;
  }>;
  createdAt: Date;
  wallet?: WalletModel
};

export type GetMissionParams = {
  valueGreater?: number | undefined;
  valueLesser?: number | undefined;
  companyValue?: string | undefined;
  dateMax?: Date | undefined;
  dateMin?: Date | undefined;
  status?: string | undefined;
  startsDateMin?: Date | undefined;
  startsDateMax?: Date | undefined;
  endsDateMin?: Date | undefined;
  endsDateMax?: Date | undefined;
}
export const listCompanyMission = async (
  companyId: CompanyModel['id'],
  params?:GetMissionParams) => {
  const { data } = await api.request({
    method: 'get',
    url: `mission/listByCompany/${companyId}`,
    params
  });
  return data;
};

export const listUserGroupsMission = async (params?:GetMissionParams) => {
  const { data } = await api.request({
    method: 'get',
    url: `mission/listByUserGroups`,
    params
  });
  return data;
};

export const subscribeToMission = async (missionId: MissionModel['id']) => {
  const { data } = await api.request({
    method: 'POST',
    url: `mission/subscribe/${missionId}`,
  });
  return data;
};

export const finishMission = async (
  missionId: MissionModel['id'],
  file: File | undefined
) => {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }
  const { data } = await api.request({
    method: 'POST',
    url: `mission/finish/${missionId}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
  return data;
};
