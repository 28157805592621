// import { getToken } from 'utils/jwt';
import api from './axios';

export enum TransactionType {
  MISSION = 'mission',
  RECOGNITION = 'recognition',
  PURCHASE = 'purchase',
}

export type Feed = {
  id: string;
  userId: string;
  value: number;
  createdBy: string;
  companyId: string;
  createdAt: string;
  transactionType: string;
  additionalInformation: {
    companyValue?: string;
    companyValues?: string[];
    message: string;
    gif: string;
  };
  transactionId: string;
  likes: string[];
  comments: Array<{
    id: string;
    displayName: string;
    img: string;
    message: string;
  }>;
};

export type AddFeed = {
  companyValue: string;
  message: string;
  meritus: number;
  companyId: string;
  gif?: string;
};

export interface getFeedResponse extends Feed {
  to?: { displayName: string; id: string; img: string };
  from?: { displayName: string; id: string; img: string };
}

export type listFeedResponse = getFeedResponse[];

export type ListUserParams = {
  offset?: number;
  limit?: number;
  dateMax?: Date | undefined;
  dateMin?: Date | undefined;
  from?: string | undefined;
  to?: string | undefined;
  companyValues?: string | undefined;
  pointsMax?: number | undefined;
  pointsMin?: number | undefined;
  isFilteringByGroup?: boolean | undefined;
  company?: string | undefined;
  transactionType?: TransactionType | undefined;
};

export const listCompanyFeed = async (
  companyId: string,
  query?: ListUserParams
) => {
  const { data } = await api.get<listFeedResponse>(
    `feed/company/${companyId}`,
    { params: query }
  );
  return data;
};

export const listUserFeed = async (userId: string, query?: ListUserParams) => {
  const { data } = await api.get<listFeedResponse>(`/feed/user/${userId}`, {
    params: query,
  });
  return data;
};

export const listUserSendFeed = async (userId: string, query?: ListUserParams) => {
  const { data } = await api.get<listFeedResponse>(`/feed/userSend/${userId}`, {
    params: query,
  });
  return data;
};

export const getFeed = async (feedId: string) => {
  const { data } = await api.get<getFeedResponse>(`/feed/${feedId}`);
  return data;
};

export const addComment = async (contentId: string, message: string) => {
  if (message.length >= 300)
    throw new Error('Commentário deve ser menor que 300 caracteres');
  const result = await api.request({
    method: 'post',
    url: `feed/addComment/${contentId}`,
    data: { message },
  });
  return result;
};

export const addLike = async (contentId: string) => {
  const result = await api.request({
    method: 'post',
    url: `feed/addLike/${contentId}`,
  });
  return result;
};

export const removeLike = async (contentId: string) => {
  const result = await api.request({
    method: 'delete',
    url: `feed/removeLike/${contentId}`,
  });
  return result;
};
