import React from 'react';
import {
  Formik,
  Form,
  FieldArray,
  useFormikContext,
  useField,
  FormikHelpers,
} from 'formik';
import SingleSpreadedSelect, {
  SingleSpreadedSelectOptions,
} from 'Components/Formik/SingleSpreadedSelect';
import { SelectOptions } from 'Components/Formik/SelectField';
import { listDisplayName } from 'utils/api/users';
import { useEffect } from 'react';
import { useState } from 'react';
import { getCompany } from 'utils/api/company';
import { recognizeSchema } from 'utils/validators/recognize';
import InputText from 'Components/Formik/InputText';
import InputTextArea from 'Components/Formik/InputTextArea';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import { AddRecognize, addRecognize } from 'utils/api/recognize';
import Tenor from './Tenor';
import { registerShare } from 'utils/tenor';
import { ReactComponent as XIcon } from 'assets/vectors/x.svg';
import { i18n } from 'utils/i18n/index';
import SelectUserField from './Formik/SelectUserField';
import { toast } from 'react-toastify';

interface MakeRecognitionProps {
  cancel: (reload: boolean) => void;
}

interface TotalMeritusFieldProps {
  meritus: string;
  selectedUser: string[];
}

const TotalMeritusField = (props: any) => {
  const { values, touched, setFieldValue } =
    useFormikContext<TotalMeritusFieldProps>();

  const [field, meta] = useField(props);

  React.useEffect(() => {
    setFieldValue(
      props.name,
      (
        (parseInt(values.meritus) || 0) *
        values.selectedUser.filter((item: string) =>
          item !== '' ? true : false
        ).length
      ).toString()
    );
  }, [
    values,
    setFieldValue,
    props.name,
    touched.meritus,
    touched.selectedUser,
  ]);

  return (
    <div className="relative">
      <input {...props} {...field} disabled className="bg-white" />
      {!!meta.touched && !!meta.error && (
        <p className="text-xs text-red absolute font-bold">{meta.error}</p>
      )}
    </div>
  );
};

const MakeRecognition: React.FC<MakeRecognitionProps> = ({ cancel }) => {
  // const [error, setError] = useState<string>('');
  const [companyValues, setCompanyValues] =
    useState<SingleSpreadedSelectOptions>([]);
  const { user, updateUserContext } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [users, setUsers] = useState<SelectOptions>([]);

  useEffect(() => {
    if (user?.companyId === undefined) return;
    listDisplayName().then((users) => {
      const processed = users.map((user) => ({
        label: user.displayName,
        value: user.id,
        ...user,
      }));
      setUsers(processed.filter((displaUser) => displaUser.id !== user.id));
      setLoading(false);
    });

    getCompany(user?.companyId || '').then((data) => {
      const { values } = data;
      const processed = values.map((value) => ({
        label: value.name,
        value: value.name,
      }));
      setCompanyValues(processed);
    });
  }, [user]);

  // stop initialValue execution if user is not loaded
  if (user == null)
    return (
      <div className="bg-white p-3 lg:p-8 rounded w-full lg:w-5/12">
        <div className="w-full flex justify-end">
          <div
            onClick={() => {
              cancel(false);
            }}
            className="cursor-pointer"
          >
            <XIcon className="text-darker-50 fill-current stroke-current w-4" />
          </div>
        </div>
        Loading....
      </div>
    );

  const initialValues = {
    selectedUser: [''],
    meritus: '',
    checkedValue: '',
    message: '',
    meritMoney: user?.meritMoney,
    selectedGif: { id: '', url: '', term: '' },
    totalMeritus: '0',
  };

  const handleSubmit = async (
    value: typeof initialValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    if (!user) return console.log('userContext not defined');
    if (!user.companyId)
      return console.log('user companyId on userContext not defined');

    setSubmitting(true);

    const { selectedUser, checkedValue, meritus, message, selectedGif } = value;

    const processedValues: AddRecognize = {
      gif: selectedGif.url,
      companyValue: checkedValue,
      message,
      companyId: user.companyId,
      meritus: parseInt(meritus),
    };

    await registerShare(selectedGif.id, selectedGif.term);

    const handleRecognize = async () => {
      for (const user of selectedUser) {
        try {
          await addRecognize(user, processedValues);
        } catch {
          break;
        }
      }
    };

    const recognitionsPromise = handleRecognize();

    toast
      .promise(recognitionsPromise, {
        pending: i18n.t('ProcessingRecognition'),
        error: i18n.t('somethingWrong'),
        success: {
          render: () => {
            updateUserContext();
            cancel(true);
            return i18n.t('RecognitionWasSuccessful');
          },
        },
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="bg-white p-3 lg:p-8 rounded w-full lg:w-5/12">
      <div className="w-full flex justify-end">
        <div
          onClick={() => {
            cancel(false);
          }}
          className="cursor-pointer"
        >
          <XIcon className="text-darker-50 fill-current stroke-current w-4" />
        </div>
      </div>
      <Formik
        validationSchema={recognizeSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {() => (
          <Form className="w-full">
            <p className="text-darker-90 text-2xl">
              {i18n.t('recognizeYourCollegues')}
            </p>
            <p>{`${i18n.t('youHave')} ${user?.meritMoney} ${i18n.t(
              'creditsToDistribute'
            )}`}</p>
            {/* <p className="text-xs text-red absolute">{error}</p> */}
            <div className="flex flex-col gap-2 mt-5">
              <p>{i18n.t('Click+ToRecognize')} </p>
              <FieldArray name="selectedUser">
                {(fieldArrayProps) => {
                  const { push, remove, form } = fieldArrayProps;
                  const { values } = form;
                  const { selectedUser } = values;
                  return (
                    <div>
                      {selectedUser.map((user: any, index: number) => (
                        <div
                          className="flex items-center  mt-2 gap-3 w-100"
                          key={index}
                        >
                          <SelectUserField
                            name={`selectedUser[${index}]`}
                            placeholder={i18n.t('selectPerson')}
                            options={users.filter((user) => {
                              if (user.value === selectedUser[index]) {
                                return true;
                              }
                              return !selectedUser.includes(user.value);
                            })}
                            className="w-2/3"
                            isLoading={loading}
                            noOptionsMessage={i18n.t('noOptions')}
                            isDisabled={index < selectedUser.length - 1}
                          />

                          <div>
                            {selectedUser[index] !== '' &&
                            index >= selectedUser.length - 1 ? (
                              <button
                                className="text-base font-bold border rounded-full px-1.5 text-center justify-center"
                                type="button"
                                onClick={() => {
                                  push('');
                                  console.log(selectedUser, index);
                                }}
                              >
                                +
                              </button>
                            ) : selectedUser[index] === '' ? (
                              ''
                            ) : (
                              <button
                                className="text-base font-bold border rounded-full px-2 text-center justify-center"
                                type="button"
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                -
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                }}
              </FieldArray>
              <p className="mt-4">{i18n.t('howMuchToDistribute')}:</p>
              <InputText
                className="w-1/3"
                name="meritus"
                placeholder={i18n.t('value')}
              />
              <p className="mt-4">{i18n.t('totalToDistribute')}:</p>
              <TotalMeritusField
                className="w-1/3"
                name="totalMeritus"
                //placeholder={(values.meritus && values.selectedUser) ? (parseInt(values.meritus) * values.selectedUser.filter((item) => item!==""? true : false).length).toString() : '' }
              />
            </div>
            <p className="mt-8">{i18n.t('thisRecognitionIsRelatedTo')}</p>
            <SingleSpreadedSelect
              name="checkedValue"
              options={companyValues}
              className=" flex flex-wrap gap-2 mt-2"
            />
            <p className=" mt-8">{i18n.t('message')}</p>
            <InputTextArea
              name="message"
              className="h-20"
              placeholder={i18n.t('typeMessage')}
            />
            <p className=" mt-8">Gif</p>
            <Tenor name="selectedGif" />
            <hr className="mt-8 border-lighter-70" />
            <div className="flex items-center justify-between mt-4">
              {/* <button className="text-primary-darker">Salvar rascunho</button> */}
              <div></div>
              <div className="flex items-center gap-2">
                <button
                  className="text-sm lg:text-base px-7 py-2 border rounded-full"
                  onClick={() => cancel(false)}
                >
                  {i18n.t('cancel')}
                </button>
                <button
                  type="submit"
                  className="px-7 py-2 bg-primary-darker text-white rounded-full disabled:opacity-30"
                  disabled={isSubmitting}
                >
                  {i18n.t('recognize')}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MakeRecognition;
