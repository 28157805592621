import { i18n } from 'utils/i18n/index';
import { UserContext } from 'Components/Context/UserContext';
import DateRange, { DateRangeDates } from 'Components/Formik/DateRange';
import InputText from 'Components/Formik/InputText';
import SelectField from 'Components/Formik/SelectField';
import SelectUserField, {
  SelectOptions,
} from 'Components/Formik/SelectUserField';
import { Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { listDisplayName } from 'utils/api/users';
import { getCompany } from 'utils/api/company';

interface FeedFiltersProps {
  handleFilters: (filters: Record<any, any>) => void;
  isReRendering?: boolean;
}

const FeedFilters: React.FC<FeedFiltersProps> = ({
  handleFilters,
  isReRendering,
}) => {
  const { user } = useContext(UserContext);

  const [users, setUsers] = useState<SelectOptions>([]);
  const [usersLoading, setUsersLoading] = useState(true);  
  const [companyValues, setCompanyValues] = useState<SelectOptions>([]);

  useEffect(() => {
    if (user?.companyId === undefined) return;
    listDisplayName().then((users) => {
      const processed = users.map((user) => ({
        label: user.displayName,
        value: user.id,
        ...user,
      }));
      setUsers(processed);
      setUsersLoading(false);
    });

    getCompany(user?.companyId || '').then((data) => {
      const { values } = data;
      
      const processed = values.map((value) => ({
        label: value.name,
        value: value.name,
      }));
      setCompanyValues(processed);
    });
  }, [ user ]);

  const initialValues = {
    datePeriod: null as DateRangeDates | null,
    from: null,
    to: null,
    companyValues: null,
    pointsMax: '',
    pointsMin: '',
    transactionType: null,
  };

  const handleSubmit = async (
    values: Partial<typeof initialValues>,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    actions.setSubmitting(true);
    const { datePeriod, ...rest } = values;

    const dates = {} as { dateMax?: Date; dateMin?: Date };
    if (datePeriod?.from) dates.dateMin = datePeriod.from;
    if (datePeriod?.to) dates.dateMax = datePeriod.to;

    // remove empty string from request
    const keys = Object.keys(rest) as Array<keyof typeof rest>;
    keys.forEach((key) => (rest[key] === '' ? (rest[key] = undefined) : {}));

    const processedValues = { ...rest, ...dates };

    await handleFilters(processedValues);
    actions.setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, resetForm, values }) => (
        <>
        {/*  {!showAdvancedFilters ? (
            <div className="w-full flex gap-3 justify-between items-center">
              <div className="flex items-center gap-5">
                <SearchIcon className="stroke-current" />
                <div
                  className={`flex items-center justify-between rounded-input shadow py-1 px-1 ${
                    isReRendering ? 'opacity-30' : ''
                  }`}
                >
                  <SelectField
                    name="companyValues"
                    placeholder="Valor"
                    className="w-40"
                    options={companyValues}
                    withoutBorder
                    onChangeCallback={() => submitForm()}
                    isDisabled={isReRendering}
                  />
                </div>
                <div
                  className={`flex items-center justify-between rounded-input shadow py-1 px-1 ${
                    isReRendering ? 'opacity-30' : ''
                  }`}
                >
                  <DateRange
                    name="datePeriod"
                    className="text-xs"
                    onChangeCallback={() => submitForm()}
                  />
                </div>
              </div>
              <button className="" onClick={() => setShowAdvancedFilters(true)}>
                <p className="text-primary hover:text-primary-darker">
                  Pesquisa avançada
                </p>
              </button>
            </div>
                ) : (*/}
            <div>
              <div className="w-full flex justify-start items-center flex-wrap gap-5 py-3 ">
 

                <div
                  className={`flex flex-col py-1 px-1 ${
                    isReRendering ? 'opacity-30' : ''
                  }`}
                > 
                <p className='text-sm mb-2'>{i18n.t('whoWasRecognized')}:</p>  
                  <SelectUserField
                    name="to"
                    options={users}
                    className="w-56 text-sm rounded-input text-current"
                    placeholder={i18n.t('selectPerson')}
                    isLoading={usersLoading}
                    noOptionsMessage={i18n.t('noOptions')}
                  />
                </div>

                <div
                  className={`flex flex-col py-1 px-1 ${
                    isReRendering ? 'opacity-30' : ''
                  }`}
                > 
                <p className='text-sm mb-2'>{i18n.t("whoRecognized")}:</p>              
                  <SelectUserField
                    name="from"
                    options={users}
                    className="w-56 text-sm rounded-input text-current"
                    placeholder={i18n.t('selectPerson')}
                    isLoading={usersLoading}
                    noOptionsMessage={i18n.t('noOptions')}
                  />
                </div>

              
                <div
                  className={`flex flex-col py-1 px-1 ${
                    isReRendering ? 'opacity-30' : ''
                  }`}
                >
                <p className='text-sm mb-2'>{i18n.t("minimumPointValue")}:</p>
                
                  <InputText
                    name="pointsMin"
                    className="w-56 text-sm rounded-input text-current"
                    placeholder={i18n.t("minimumPoints")}
                  />
                </div>            
                
                <div
                  className={`flex flex-col py-1 px-1 ${
                    isReRendering ? 'opacity-30' : ''
                  }`}
                >
                <p className='text-sm mb-2'>{i18n.t('maximumPointValue')}:</p>
                  <InputText
                    name="pointsMax"
                    className="w-56 text-sm rounded-input text-current"
                    placeholder={i18n.t("maximumValue")}
                  />
                </div>
                <div
                  className={`flex flex-col py-1 px-1 ${
                    isReRendering ? 'opacity-30' : ''
                  }`}
                >
                <p className='text-sm mb-2'>{i18n.t("transactionType")}:</p>
                  <SelectField
                    name="transactionType"
                    className="w-56 text-sm rounded-input text-current"
                    options={[
                      {
                        label: 'Reconhecimento',
                        value: 'recognition',
                      },{
                        label: 'Missão',
                        value: 'mission',
                      }
                    ]}
                    placeholder={i18n.t("selection")}
                  />
                </div>
                <div
                  className={`flex flex-col py-1 px-1 ${
                    isReRendering ? 'opacity-30' : ''
                  }`}
                >
                <p className='text-sm mb-2'>{i18n.t("companyValues")}:</p>
                  <SelectField
                    name="companyValue"
                    placeholder={i18n.t("values")}
                    className="w-56 text-sm rounded-input text-current"
                    options={companyValues}
                    // onChangeCallback={() => submitForm()}
                    isDisabled={isReRendering}
                  />
                </div>  
                <div
                  className={`flex flex-col py-1 px-1 ${
                    isReRendering ? 'opacity-30' : ''
                  }`}
                > 
                <p className='text-sm mb-2'>{i18n.t("whenRecognized")}:</p> 
                  <DateRange name="datePeriod" className="text-xs z-0  w-56" />
              </div>


                <div className="w-full grid col-start-4 row-start-3 mt-2 gap-7">
                  <div className='flex justify-end'>
                  <button
                    className="text-primary mt-2 mr-4"
                    onClick={() => {
                      resetForm({
                      values: {
                        ...initialValues,
                      },
                    });
                    submitForm();
                  }
                  }
                  >
                    {i18n.t("clean")}
                  </button>
                  <button
                    className="bg-primary-darker rounded px-6 py-2 text-white mt-2 disabled:opacity-30"
                    type="submit"
                    onClick={submitForm}
                    disabled={isReRendering}
                  >
                    {i18n.t("search")}
                  </button>
                </div>
                </div>
              { /* <div className="w-full flex justify-center">
                  <button
                    onClick={() => {
                      setShowAdvancedFilters(false);
                      resetForm({
                        values: {
                          ...initialValues,
                          companyValues: values.companyValues,
                        },
                      });
                      submitForm();
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    <p className="text-primary hover:text-primary-darker">
                      Pesquisa simples
                    </p>
                  </button>
                  </div>*/}
              </div>
            </div>
          {/*)}*/}
        </>
      )}
    </Formik>
  );
};

export default FeedFilters;
