import { UserContext } from 'Components/Context/UserContext';
import { DateRangeDates } from 'Components/Formik/DateRange';
import InputText from 'Components/Formik/InputText';
import SelectField from 'Components/Formik/SelectField';
import { SelectOptions } from 'Components/Formik/SelectUserField';
import { Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { getCompany } from 'utils/api/company';
import { i18n } from 'utils/i18n';

interface MissionFiltersProps {
  handleFilters: (filters: Record<any, any>) => void;
  isReRendering?: boolean;
}

const MissionFilters: React.FC<MissionFiltersProps> = ({
  handleFilters,
  isReRendering,
}) => {
  const { user } = useContext(UserContext);
  const [companyValue, setCompanyValue] = useState<SelectOptions>([]);

  useEffect(() => {
    if (user?.companyId === undefined) return;

    getCompany(user?.companyId || '').then((data) => {
      const { values } = data;
      const processed = values.map((value) => ({
        label: value.name,
        value: value.name,
      }));
      setCompanyValue(processed);
    });
  }, [user]);

  const status = [
    { value: 'done', label: `${i18n.t('done')}` },
    { value: 'accepted', label: `${i18n.t('inProgress')}` },
    { value: 'notAccepted', label: `${i18n.t('notAccepted')}` },
  ];

  const initialValues = {
    dateStartsPeriod: null as DateRangeDates | null,
    dateEndsPeriod: null as DateRangeDates | null,
    companyValue: null,
    valueGreater: '',
    valueLesser: '',
    status: null,
  };

  const handleSubmit = async (
    values: Partial<typeof initialValues>,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    actions.setSubmitting(true);
    const { dateStartsPeriod, dateEndsPeriod, ...rest } = values;

    const datesStarts = {} as { startsDateMax?: Date; startsDateMin?: Date };
    if (dateStartsPeriod?.from)
      datesStarts.startsDateMin = dateStartsPeriod.from;
    if (dateStartsPeriod?.to) datesStarts.startsDateMax = dateStartsPeriod.to;

    const datesEnds = {} as { endsDateMax?: Date; endsDateMin?: Date };
    if (dateEndsPeriod?.from) datesEnds.endsDateMin = dateEndsPeriod.from;
    if (dateEndsPeriod?.to) datesEnds.endsDateMax = dateEndsPeriod.to;

    // remove empty string from request
    const keys = Object.keys(rest) as Array<keyof typeof rest>;
    keys.forEach((key) => (rest[key] === '' ? (rest[key] = undefined) : {}));

    const processedValues = { ...rest, ...datesStarts, ...datesEnds };

    await handleFilters(processedValues);
    actions.setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ submitForm, resetForm, values }) => (
        <>
          <div>
            <div className="w-full flex gap-5 justify-start items-start flex-wrap py-3">
              <div
                className={`flex flex-col py-1 px-1 ${
                  isReRendering ? 'opacity-30' : ''
                }`}
              >
                <p className="text-sm mb-2">{i18n.t('companyValues')}</p>
                <SelectField
                  name="companyValue"
                  placeholder={i18n.t('values')}
                  className="w-40 text-sm rounded-input text-current"
                  options={companyValue}
                  isDisabled={isReRendering}
                />
              </div>

              <div
                className={`flex flex-col  py-1 px-1 ${
                  isReRendering ? 'opacity-30' : ''
                }`}
              >
                <p className="text-sm mb-2">{i18n.t('minimumPointValue')}:</p>
                <InputText
                  name="valueGreater"
                  className="w-40 text-sm rounded-input text-current"
                  placeholder={i18n.t('minimumPoints')}
                />
              </div>

              <div
                className={`flex flex-col py-1 px-1 ${
                  isReRendering ? 'opacity-30' : ''
                }`}
              >
                <p className="text-sm mb-2">{i18n.t('maximumPointValue')}:</p>
                <InputText
                  name="valueLesser"
                  className="w-40 text-sm rounded-input text-current"
                  placeholder={i18n.t('maximumValue')}
                />
              </div>

              <div
                className={`flex flex-col py-1 px-1 ${
                  isReRendering ? 'opacity-30' : ''
                }`}
              >
                <p className="text-sm mb-2">{i18n.t('missionStatus')}:</p>
                <SelectField
                  name="status"
                  className="w-40 text-sm rounded-input text-current"
                  options={status}
                  placeholder="Status"
                />
              </div>
              {/* Todo return this inputs when bug is resolved */}
              {/* <div
                className={`flex flex-col py-1 ${
                  isReRendering ? 'opacity-30' : ''
                }`}
              >
                <p className="text-sm mb-2">{i18n.t('missionStartPeriod')}:</p>
                <DateRange name="dateStartsPeriod" className="w-40 text-xs" />
              </div> */}
              {/* <div
                className={`flex flex-col py-1 px-2 ${
                  isReRendering ? 'opacity-30' : ''
                }`}
              >
                <p className="text-sm mb-2">
                  {i18n.t('missionExpirationTime')}:
                </p>
                <DateRange name="dateEndsPeriod" className="text-xs" />
              </div> */}

              <div className="w-full flex justify-end mt-2 gap-7">
                <button
                  className="text-primary mt-2"
                  onClick={() => {
                    resetForm({
                      values: {
                        ...initialValues,
                      },
                    });
                    submitForm();
                  }}
                >
                  {i18n.t('clean')}
                </button>
                <button
                  className="bg-primary-darker rounded px-6 py-2 text-white mt-2 disabled:opacity-30"
                  type="submit"
                  onClick={submitForm}
                  disabled={isReRendering}
                >
                  {i18n.t('search')}
                </button>
              </div>
              {/* <div className="w-full flex justify-center">
                  <button
                    onClick={() => {
                      setShowAdvancedFilters(false);
                      resetForm({
                        values: {
                          ...initialValues,
                          companyValue: values.companyValue,
                          status: values.status,
                          dateEndsPeriod: values.dateEndsPeriod,
                          valueLesser: values.valueLesser,
                          valueGreater: values.valueGreater
                        },
                      });
                      submitForm();
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    <p className="text-primary hover:text-primary-darker">
                      Pesquisa simples
                    </p>
                  </button>
                </div>*/}
            </div>
          </div>
          {/*}*/}
        </>
      )}
    </Formik>
  );
};

export default MissionFilters;
