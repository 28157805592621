import React, { useContext, useState } from 'react';

import { ReactComponent as Maximize } from 'assets/vectors/maximize.svg';

import Tag from 'Components/Tag';
import LikeAndComment from 'Components/LikeAndComment';
import GhostProfilePicture from 'Components/Ghost/GhostProfilePicture';
import Comments from '../../../Components/Comments';
import { getFeedResponse } from 'utils/api/feed';
import { UserContext } from 'Components/Context/UserContext';
import { i18n } from 'utils/i18n/index';
import { Link } from 'react-router-dom';

interface RecognizeItemProps {
  recognitionObject: getFeedResponse;
  alwaysShowComment?: boolean;
  hideMaximize?: boolean;
}

const RecognizeItem: React.FC<RecognizeItemProps> = ({
  recognitionObject,
  alwaysShowComment,
  hideMaximize,
}) => {
  const { user } = useContext(UserContext);
  const date = new Date(recognitionObject.createdAt);
  const [recognition, setRecognition] = useState(recognitionObject);

  if (recognition.transactionType === 'purchase') return <></>;

  return (
    <div id="recognition" className="shadow rounded py-4 px-2 lg:px-7 lg:mt-4">
      <div className="flex items-center lg:py-6 w-full">
        <div className="flex items-center justify-center relative w-20 lg:w-36 flex-shrink-0">
          {recognition.from && (
            <div className="w-full transform translate-x-2 rounded-full border-4 border-solid border-white">
              <Link to={`/colaborador/perfil/${recognition.from.id}`}>
                <GhostProfilePicture
                  name={recognition.from?.displayName}
                  alt=""
                  src={recognition.from?.img}
                />
              </Link>
            </div>
          )}
          <div
            className={`w-full transform rounded-full border-2 lg:border-4 border-solid border-white ${
              recognition.transactionType === 'mission'
                ? 'w-1/2'
                : '-translate-x-2'
            }`}
          >
            <Link to={`/colaborador/perfil/${recognition.to?.id}`}>
              <GhostProfilePicture
                name={recognition.to?.displayName}
                alt=""
                src={recognition.to?.img}
              />
            </Link>
          </div>
        </div>
        <div className="flex-grow lg:px-5 items-center flex-shrink w-full break-normal overflow-auto">
          <div className="flex justify-between items-center">
            <div className="flex items-baseline w-full">
              <div>
                <Link to={`/colaborador/perfil/${recognition.to?.id}`}>
                  <span className="text-black">
                    {recognition.to?.displayName}{' '}
                  </span>
                </Link>
                <span className="hidden lg:inline">
                  {recognition.transactionType === 'mission'
                    ? `${i18n.t('completedMission')}`
                    : `${i18n.t('recognizedBy')}`}
                </span>
                <span className="text-black hidden lg:inline">
                  {recognition?.additionalInformation?.companyValue}
                </span>
              </div>
              <Tag alternative className="ml-2">{`+ ${recognition.value}`}</Tag>
            </div>
            {hideMaximize ? (
              ''
            ) : (
              <div className="right-1">
                <Link to={`/colaborador/reconhecimento/${recognition.id}`}>
                  <Maximize className="w-5" />
                </Link>
              </div>
            )}
            {/* <div className="bg-gray rounded-sm py-1">
              <DottedIcon className="h-full w-auto" />
            </div> */}
          </div>
          <div className="flex gap-3">
            {recognition.transactionType === 'mission' ? (
              <>
                {recognition.additionalInformation?.companyValues?.map(
                  (value) => (
                    <div
                      className="rounded-full mt-3 px-4 py-1 text-black bg-neutral"
                      key={recognition.id + value}
                    >
                      {value}
                    </div>
                  )
                )}
              </>
            ) : (
              ''
            )}
          </div>
          <div className="hidden lg:inline-block my-5 p-3 w-full overflow-auto">
            <p className="text-black mt-2">{`"${recognition?.additionalInformation?.message}"`}</p>
          </div>
          <img
            src={recognition?.additionalInformation?.gif}
            alt=""
            className="w-1/6 mt-2 hidden lg:block"
          />
          <p className="mt-1 ml-3 hidden lg:inline">
            {recognition.from?.displayName && (
              <Link to={`/colaborador/perfil/${recognition.from.id}`}>
                {`-${recognition.from.displayName}`}
              </Link>
            )}
          </p>
        </div>
      </div>
      <div className="w-full">
        <div className="lg:hidden ml-5 overflow-auto break-normal">
          <span className="">
            {recognition.transactionType === 'mission'
              ? `${i18n.t('completedMission')}`
              : `${i18n.t('recognizedBy')}`}
          </span>
          <span className="text-black">
            {recognition?.additionalInformation?.companyValue}
          </span>
          <div className="my-5 p-3">
            <p className="text-black">{`"${recognition?.additionalInformation?.message}"`}</p>
          </div>
          <img
            src={recognition?.additionalInformation?.gif}
            alt=""
            className="w-1/3 mt-2 ml-3"
          />
          <p className="mt-1 ml-3">
            {recognition.from?.displayName && (
              <Link to={`/colaborador/perfil/${recognition.from.id}`}>
                {`-${recognition.from.displayName}`}
              </Link>
            )}
          </p>
        </div>
      </div>
      <hr className="border-lighter-20" />

      <LikeAndComment
        className="w-full mt-4"
        date={date}
        recognition={recognition}
        contentId={recognition.id}
        likes={recognition.likes}
        alwaysShowComment={alwaysShowComment}
        updateComments={(comment: string) =>
          setRecognition({
            ...recognition,
            comments: [
              ...recognition.comments,
              {
                message: comment,
                displayName: user?.displayName || '',
                img: user?.img || '',
                id: user?.id || '',
              },
            ],
          })
        }
      />

      <Comments
        comments={recognition.comments}
        alwaysShowComment={alwaysShowComment}
      />
    </div>
  );
};

export default RecognizeItem;
