import React from 'react';
import { CompanyModel } from 'utils/api/company';

interface ImprovementsProps {
  company: Partial<CompanyModel>;
  selected: string[];
  setSelected: (value: string[]) => void;
}

const Improvements: React.FC<ImprovementsProps> = ({
  company,
  selected,
  setSelected,
}) => {
  const toggleSelect = (value: string) => {
    if (selected.includes(value)) {
      setSelected(selected.filter((x) => x !== value));
    } else {
      setSelected([...selected, value]);
    }
  };

  return (
    <div className='text-sm lg:text-base'>
      <div className="text-xl lg:text-3xl mt-10 font-normal">
        {company?.alternativeText?.improvements?.title ??
          'Vamos crescer juntos?'}
        <div className="text-sm lg:text-base font-extralight">
          {company?.alternativeText?.improvements?.desc ??
            'Esses são os valores da sua empresa. Quais valores você acredita que pode colaborar mais? Você poderá acompanhar posteriormente a sua evolução em cada valor, ao longo da sua jornada de reconhecimento com seus colegas!'}
        </div>
      </div>
      <main className="flex flex-col lg:grid lg:grid-cols-3 gap-3 lg:gap-7 mt-10 lg:mt-16 mb-5">
        {Array.isArray(company) || !company?.values ? (
          <p>:,(</p>
        ) : (
          company?.values.map((value) => (
            <div
              className={`${
                selected.find((x) => x === value.name)
                  ? 'border-primary-darker'
                  : 'border-white'
              } flex flex-col justify-center cursor-pointer bg-neutral text-sm font-normal text-gray-400 rounded-input border box-border border-solid hover:border-secondary p-3 lg:p-5`}
              onClick={() => toggleSelect(value.name)}
            >
              <p className="text-base lg:text-lg font-semibold">{value.name}</p>
              <p className="text-xs lg:text-sm font-light">{value.desc}</p>
            </div>
          ))
        )}
      </main>
    </div>
  );
};

export default Improvements;
