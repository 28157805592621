import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import history from './utils/history';
import { env } from 'config';

import {
  UserContextProvider,
} from 'Components/Context/UserContext';

import Login from './Pages/Colaborador/Login';
import SignUp from './Pages/Colaborador/Signup';
import Dashboard from './Pages/Colaborador/Dashboard';
import Loja from './Pages/Colaborador/Loja';
import Menu from './Pages/Colaborador/Menu';
import Recognize from './Pages/Colaborador/Recognize';
import Mission from './Pages/Colaborador/Missions';
import Company from './Pages/Colaborador/Company';
import Profile from './Pages/Colaborador/Profile';
import Recognition from 'Pages/Colaborador/Recognition';
import Logout from 'Pages/Colaborador/Logout';
import Product from 'Pages/Colaborador/Product';
import Purchases from 'Pages/Colaborador/Purchases';
import PasswordRecovery from 'Pages/Colaborador/PasswordRecovery';
import TermsAndConditions from 'Pages/Colaborador/TermsOfService';

import { IdleTimer } from 'Components/IdleTimer';

import './global.css';

function App() {
  return (
    <Router history={history}>
      <UserContextProvider>
        {env === 'gerdau' ? <IdleTimer /> : <></>}
        <Switch>
          <Route
            path="/finishRegistration/:inviteId"
            exact
            component={SignUp}
          />
          <Route path="/termsOfUse" exact component={TermsAndConditions} />
          <Route path="/" exact component={Login} />
          <Route
            path="/recuperarSenha/:passwordRecoveryId"
            exact
            component={PasswordRecovery}
          />
          <Route path="/colaborador/" exact component={Menu} />
          <Route path="/colaborador/dashboard" exact component={Dashboard} />
          <Route path="/colaborador/trocas" exact component={Purchases} />
          <Route path="/colaborador/loja" exact component={Loja} />
          <Route
            path="/colaborador/loja/:productDisplayId"
            exact
            component={Product}
          />
          <Route path="/colaborador/empresa" exact component={Company} />
          <Route
            path="/colaborador/reconhecimento"
            exact
            component={Recognize}
          />
          <Route path="/colaborador/reconhecimento/:feedId" exact component={Recognition} />
          <Route path="/colaborador/perfil" exact component={Profile} />
          <Route path="/colaborador/perfil/:userId" exact component={Profile} />
          <Route path="/colaborador/missao" exact component={Mission} />
          <Route path="/logout" exact component={Logout} />
          <Route path="*">
            <div>404! url não encontrado</div>
          </Route>
        </Switch>
      </UserContextProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default App;
