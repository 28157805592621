import GhostProfilePicture from 'Components/Ghost/GhostProfilePicture';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getFeedResponse } from 'utils/api/feed';
import { i18n } from 'utils/i18n';

interface CommentsProps {
  comments?: getFeedResponse['comments'];
  alwaysShowComment?: boolean;
}

const Comments: React.FC<CommentsProps> = ({ comments, alwaysShowComment }) => {
  const [showComments, setShowComments] = useState(alwaysShowComment);
  if (!comments || comments.length <= 0) return <></>;
  return (
    <div>
      {!alwaysShowComment && comments.length > 1 && (
        <button
          className="mt-5 text-black hover:text-primary-darker"
          onClick={() => setShowComments(!showComments)}
        >
          {showComments ? i18n.t("hideComments") : i18n.t("seeAllComments")}
          {` (${comments.length})`}
        </button>
      )}
      <div className="flex flex-col gap-3 mt-3">
        <hr className="border-lighter-20" />
        {comments.map((comment, index) => {
          if (!showComments && index <= comments.length - 2) return undefined;
          return (
            <div key={index} className="flex gap-2">
              <div className="w-11 lg:w-16 relative p-2 break-normal overflow-auto">
                <Link to={`/colaborador/perfil/${comment.id}`}>
                  <GhostProfilePicture
                    name={comment.displayName}
                    src={comment.img}
                    alt=""
                    className="w-full rounded-full absolute"
                  />
                </Link>
              </div>
              <div className="bg-gray w-full overflow-auto p-4 rounded mr-2">
                <Link to={`/colaborador/perfil/${comment.id}`}>
                  <p className="text-black">{comment.displayName}</p>
                </Link>
                <p className="">{comment.message}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Comments;
