import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Field, Form, Formik } from 'formik';

import Password from './steps/Password';
import Improvements from './steps/Improvements';
import CompanyDesc from './steps/CompanyDesc';

import { ReactComponent as Logo } from '../../../assets/vectors/logo.svg';

import {
  fileUpload,
  finishRegistration,
  UserModel,
} from '../../../utils/api/users';
import { registerUserSchema } from 'utils/validators/registration';
import { CompanyModel, getCompany } from 'utils/api/company';
import { getInvite } from 'utils/api/invites';
import history from 'utils/history';
import { loginCookie } from 'utils/jwt';
import PopUp from 'Components/PopUp';

export interface SignUpProps {}

const initialValues = {
  file: undefined,
  password: '',
  confirm: '',
  backupEmail: '',
  confirmBackupEmail: '',
  displayName: '',
  // jobTitle: '',
  img: '',
  mobile: '',
  // postalCode: '',
  // street: '',
  // numberAndComplement: '',
  // district: '',
  // city: '',
  // state: '',
  bio: '',
  termsOfUse: false,
  birthday: new Date(),
};

export type InitialValues = typeof initialValues;

const SignUp: React.FC<SignUpProps> = (props) => {
  let { inviteId } = useParams<{ inviteId: string }>();

  const [activeStep, setActiveStep] = useState(0);
  const [showImagePicker, setShowImagePicker] = useState(false);
  const [image, setImage] = useState<File | undefined>();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [user, setUser] = useState<Pick<UserModel, 'name' | 'companyId'>>({
    name: 'Loading...',
    companyId: '',
  });

  const [company, setCompany] = useState<Partial<CompanyModel>>({
    name: 'loading...',
    values: [],
    mission: 'loading...',
    vision: 'loading...',
    logo: 'loading...',
    desc: 'loading...',
    alternativeText: {
      improvements: {
        name: '',
        desc: '',
        title: '',
      },
    },
  });

  const steps = [
    {
      title: 'Cadastro',
      render: () => {
        return <Password user={user} setShowImagePicker={setShowImagePicker} />;
      },
    },
    {
      title: company?.alternativeText?.improvements?.name ?? 'Valores',
      render: () => {
        return (
          <Improvements
            company={company}
            selected={selectedValues}
            setSelected={setSelectedValues}
          />
        );
      },
    },
    { title: 'Empresa', render: () => <CompanyDesc company={company} /> },
  ];

  async function submitForm(params: any, actions: any) {
    await finishRegistration(inviteId, params)
      .then((data) => {
        loginCookie(data.jwt, 15, '');
        history.push('/colaborador/reconhecimento');
      })
      .catch((a) => {
        // console.log(a);
        alert('Algo deu errado tente mais tarde');
      });
    actions.setSubmitting(false);
  }

  const handleBack = () => {
    setShowImagePicker(false);
    window.scrollTo(0, 0);
    if (activeStep === 0) {
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleSubmit = async (values: InitialValues, actions: any) => {
    setShowImagePicker(false);
    window.scrollTo(0, 0);
    if (activeStep === steps.length - 1) {
      const { confirm, confirmBackupEmail, ...rest } = values;

      const displayName = values.displayName ?? user.name;
      const params = {
        ...rest,
        displayName,
        selectedValues,
      };
      await submitForm(params, actions);
      actions.setSubmitting(false);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    getInvite(inviteId).then((data) => {
      const { companyId, name, status } = data;
      if (status === 'pending') {
        return setUser({ companyId, name });
      }
      if (status === 'complete') {
        alert('Usuário já cadastrado');
      } else {
        alert('Houve algum problema');
      }
      history.push('/');
    });
  }, [inviteId]);

  useEffect(() => {
    if (!user.companyId) return;
    getCompany(user.companyId).then(setCompany);
  }, [user]);

  return (
    <div className="text-sm lg:text-base">
      <Formik
        initialValues={initialValues}
        validationSchema={registerUserSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <div>
            <PopUp show={showImagePicker}>
              <div className="p-10 rounded bg-lighter-20 ">
                <form>
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files === null) return;
                      const file = Array.from(e.target.files);
                      setImage(file[0]);
                    }}
                    className="w-full h-10 px-2 border border-lighter-70 rounded-input focus:outline-none"
                  />
                  <div className="flex w-full justify-between items-center py-5">
                    <button
                      className="text-center py-2 px-14 border box-border border-solid border-black rounded-full"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowImagePicker(false);
                      }}
                    >
                      Voltar
                    </button>
                    <button
                      type="submit"
                      className="text-center py-2 px-14 rounded-full bg-primary-darker text-white items-baseline disabled:opacity-30"
                      disabled={uploadingImage}
                      onClick={async (e) => {
                        e.preventDefault();
                        if (!image) return alert('Selecione a imagem');
                        setUploadingImage(true);
                        const response = await fileUpload(image);
                        setFieldValue('img', response);
                        setShowImagePicker(false);
                        setUploadingImage(false);
                      }}
                    >
                      Próximo
                    </button>
                  </div>
                </form>
              </div>
            </PopUp>
            <Form>
              <div className="flex flex-col items-center h-screen w-screen">
                <div className="flex flex-col justify-between items min-h-full w-10/12 py-7">
                  <div className="flex justify-between items-center static z-20">
                    <div className="font-bold text-3xl lg:text-4xl flex items-center gap-1">
                      <Logo />
                      <p className="text-black">meritu</p>
                    </div>
                    <div className="text-right ">
                      Etapa {'0' + (activeStep + 1)}/{'0' + steps.length}
                      <h4>{steps[activeStep].title}</h4>
                    </div>
                  </div>
                  <div className="">{steps[activeStep].render()}</div>
                  <div>
                    <div className="w-full flex-grow-0 flex items-baseline justify-end">
                      <Field
                        type="checkbox"
                        name="termsOfUse"
                        className="mr-3"
                      />
                      <p>
                        Eu li e aceito os
                        <a
                          href="https://storage.googleapis.com/meritu-co-storage/terms-of-use/terms-of-useV1.0.0.pdf"
                          target="_blank"
                          rel="noreferrer nofollow"
                          className="text-primary-darker"
                        >
                          {' '}
                          termos de uso
                        </a>
                      </p>
                    </div>
                    <div className="flex gap-2 lg:gap-4 mt-6 w-full justify-end items-center mb-5">
                      <button
                        className="text-center py-2 px-5 lg:px-14 border box-border border-solid border-black rounded-full"
                        type="button"
                        onClick={handleBack}
                      >
                        Voltar
                      </button>
                      <button
                        type="submit"
                        className="text-center py-2 px-5 lg:px-14 rounded-full bg-primary-darker text-white items-baseline disabled:opacity-30"
                        disabled={!values.termsOfUse || isSubmitting}
                      >
                        Próximo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SignUp;
