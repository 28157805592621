import * as yup from 'yup'
import {ptShort} from 'yup-locale-pt'

yup.setLocale(ptShort)

export const registerUserSchema = yup.object().shape({
    password: yup.string().required(),
    confirm: yup.string().required().oneOf([yup.ref('password'), null], 'Passwords must match'),
    // postalCode: yup.string().required(),
    // street: yup.string().required(),
    // numberAndComplement: yup.string().required(),
    // district: yup.string().required(),
    // city: yup.string().required(),
    // state: yup.string().required(),
    backupEmail: yup.string().email(),
    confirmBackupEmail: yup.string().oneOf([yup.ref('backupEmail'), null], 'Email must match'),
    mobile: yup.string(),
    displayName: yup.string().required(),
    // jobTitle: yup.string(),
    birthday: yup.date().max(new Date(),"Data invalida").required(),
    bio: yup.string().trim().ensure().min(3).max(500),
  }).noUnknown(true)