import * as yup from 'yup'
import {ptShort} from 'yup-locale-pt'

yup.setLocale(ptShort)

export const UpdateProfileSchema = yup.object().shape({
    password: yup.string(),
    confirm: yup.string()
    .test('passwords-match', 'Confirmção de senha invalida', function(value){
      return this.parent.password === value
    }),
    backupEmail: yup.string().email(),
    mobile: yup.string(),
    displayName: yup.string().required(),
    bio: yup.string().trim().ensure().min(3).max(500),
  }).noUnknown(true)


export const UpdateUserPassword = yup.object().shape({
  password: yup.string().required().min(3),
    confirm: yup.string().required()
    .test('passwords-match', 'Confirmção de senha invalida', function(value){
      return this.parent.password === value
    }),
}) 