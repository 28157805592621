import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import { ReactComponent as BriefcaseIcon } from '../../assets/vectors/briefcase.svg';
//import photo from "../../assets/images/empresas.png";
import diamond from '../../assets/vectors/diamond.svg';
import { CompanyModel } from 'utils/api/company';
import GhostProfilePicture from 'Components/Ghost/GhostProfilePicture';
import ReadMore from 'Components/ReadMore';
import { getCompany } from 'utils/api/company';
import Navbar from '../../Components/Navbar';
import { i18n } from 'utils/i18n/index';
import AutoMargin from '../../Components/AutoMargin';

interface CompanyProps {
  company: Partial<CompanyModel>;
}

const Company: React.FC<CompanyProps> = () => {
  const { user, updateUserContext } = useContext(UserContext);

  const [companyData, setCompanyData] = useState<
    CompanyModel | null | undefined
  >();

  useEffect(() => {
    if (user?.companyId) {
      getCompany(user.companyId).then((response) => {
        setCompanyData(response);
      });
    }
  }, [user]);

  return (
    <div>
      <Navbar />
      <AutoMargin className="w-full mt-8 p-6 shadow rounded max-w-7xl">
        <div className="px-10 mt-7">
          <p className="text-xs">
            DASHBOARD /{' '}
            <span className="text-primary">{i18n.t('companyMenu')}</span>
          </p>
          <p className="text-black text-xl lg:text-3xl mt-2 lg:mt-4">
            {i18n.t('company')}
          </p>
        </div>
        <div className="flex items-center justify-center mb-5 mt-10 mx-10">
          <main className="col-span-4">
            <div className="flex flex-col lg:flex-row items-center gap-3">
              <div className="w-24">
                <GhostProfilePicture
                  src={companyData?.logo}
                  alt="company logo"
                  name={companyData?.name}
                />
              </div>
              <div className="text-center lg:text-left">
                <p className="text-2xl lg:text-4xl text-black font-medium">
                  {companyData?.name}
                </p>
                <p className="text-black">{companyData?.slogan}</p>
              </div>
            </div>
            <ReadMore
              className="mt-4 mb-16"
              text={companyData?.desc || ''}
              numSplit={300}
            />
            <div className="w-full">
              <div>
                <div className="flex items-center gap-3 mb-8">
                  <BriefcaseIcon />
                  <p className="mt-3 mb-2 text-lg text-black">
                    {i18n.t('values')}
                  </p>
                </div>
                <div className="grid grid-cols-350 gap-5">
                  {companyData?.values?.map((companyValue) => (
                    <div className="shadow rounded p-5">
                      <div className="flex items-start gap-3 mb-5">
                        <img src={diamond} alt="" className="w-5" />
                        <p className="text-black text-base font-semibold">{`${companyValue.name}:`}</p>
                      </div>
                      <p className="text-base">{companyValue.desc}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </main>
          {/* <div className="hidden lg:flex max-h-screen w-full col-span-3 items-center justify-center">
        <div className="w-3/4 rounded-full relative">
          <img
            src={photo}
            alt=""
            className="rounded-full min-w-min h-auto z-10 absolute transform -translate-y-1/2"
              />
          <div className="bg-gray min-w-full h-screen transform bottom-0 absolute z-0 after"></div>
        </div>
      </div>*/}
        </div>
      </AutoMargin>
    </div>
  );
};

export default Company;
