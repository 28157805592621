import Navbar from 'Components/Navbar';
import AutoMargin from 'Components/AutoMargin';
import Tag from 'Components/Tag';
import { useContext, useEffect, useState } from 'react';

import MakeRecognition from 'Components/MakeRecognition';
import PopUp from 'Components/PopUp';
import { UserContext } from 'Components/Context/UserContext';
import GhostProfilePicture from 'Components/Ghost/GhostProfilePicture';

import { ReactComponent as AvaliableMeritusIcon } from 'assets/vectors/avaliableMeritus.svg';
import Square from 'Components/Square';
import UpdateUser from '../UpdateUser';
import RecognizeList from './RecognizeList';
import ChangePassword from '../ChangePassword';
import { i18n } from 'utils/i18n/index';
import { useParams } from 'react-router-dom';
import { getUser, UserModel } from 'utils/api/users';

interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => {
  const { user } = useContext(UserContext);

  const { userId } = useParams<{ userId: string | undefined }>();

  const [tab, setTab] = useState<'received' | 'send'>('received');
  const [reRenderToggle, setRerendererToggle] = useState<boolean>(true);
  const [showMakeRecognition, setShowMakeRecognition] =
    useState<boolean>(false);
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const [showChangePassword, setChangePassword] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<
    Partial<UserModel> | undefined | null
  >();

  const [isSelf, setIsSelf] = useState(!userId || userId === user?.id);

  // set selected user and isSelf
  useEffect(() => {
    setIsSelf(!userId || userId === user?.id);

    if (!user?.id) return console.log('yo something went wrong');
    if (isSelf) {
      return setSelectedUser(user);
    }

    if (!userId) return console.log('is Self');
    getUser(userId)
      .then(setSelectedUser)
      .catch(() => setSelectedUser(null));
  }, [isSelf, user, userId]);

  const cancelMakeRecognition = (reRender = false) => {
    if (reRender) setRerendererToggle((x) => !x);
    setShowMakeRecognition(false);
  };

  return (
    <div className="text-sm lg:text-base">
      <PopUp show={showMakeRecognition}>
        <MakeRecognition cancel={cancelMakeRecognition} />
      </PopUp>
      <PopUp show={showEditProfile}>
        <UpdateUser cancel={() => setShowEditProfile(false)} />
      </PopUp>
      <PopUp show={showChangePassword}>
        <ChangePassword cancel={() => setChangePassword(false)} />
      </PopUp>
      <Navbar />
      {selectedUser === null ? (
        <>Usuário não encontrado</>
      ) : selectedUser === undefined ? (
        <>Carregando...</>
      ) : (
        <div className="lg:grid lg:grid-cols-3 lg:divide-solid lg:divide-x lg:divide-opacity-50 lg:divide-lighter-70">
          <div className="lg:col-span-2 px-3 lg:px-7">
            <div className="w-full">
              <div className="text-xs mt-3 lg:mt-9">
                {isSelf ? (
                  <>
                    DASHBOARD /{' '}
                    <span className="text-primary-darker">
                      {i18n.t('profileMenu')}
                    </span>
                  </>
                ) : (
                  <>
                    DASHBOARD / PERFIL /{' '}
                    <span className="text-primary-darker">
                      {selectedUser?.displayName}
                    </span>
                  </>
                )}
              </div>
              <div className="mt-4 flex justify-between items-baseline">
                <p className="text-black text-xl lg:text-3xl">
                  {isSelf ? (
                    <>{i18n.t('myProfile')}</>
                  ) : (
                    <>
                      {i18n.t('profileOf')} {selectedUser?.displayName}
                    </>
                  )}
                </p>
                {isSelf ? (
                  <div className="flex justify-center flex-col lg:flex-row gap-3">
                    <button
                      className="inline-block self-start px-4 py-1 text-primary-darker border border-primary-darker rounded-input"
                      onClick={() => setShowEditProfile(true)}
                    >
                      {i18n.t('EditProfile')}
                    </button>
                    <button
                      className="inline-block self-start px-3 py-1 text-primary-darker border border-primary-darker rounded-input"
                      onClick={() => setChangePassword(true)}
                    >
                      {i18n.t('ChangePassword')}
                    </button>
                  </div>
                ) : null}
              </div>
              <main>
                <div className="shadow rounded p-4 lg:p-8 mt-5">
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col lg:flex-row items-center gap-2 justify-start w-full">
                      <div className="w-24 lg:w-14">
                        <GhostProfilePicture
                          alt=""
                          name={selectedUser?.displayName}
                          src={selectedUser?.img}
                        />
                      </div>

                      <div className="lg:ml-3">
                        <div className="w-full flex flex-col lg:flex-col gap-2 items-center lg:items-start text-lg lg:text-xl ">
                          <p className="text-black whitespace-nowrap">
                            {selectedUser?.displayName}
                          </p>
                          <p>{selectedUser?.jobTitle}</p>
                        </div>
                        <div className="flex gap-2 flex-wrap mt-2">
                          {selectedUser?.selectedValues?.map(
                            (selectedValue) => (
                              <Tag key={selectedValue}>{selectedValue}</Tag>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 w-full text-center lg:text-left ">
                    {selectedUser?.bio}
                  </div>
                </div>
                <div className="p-2 lg:p-4 mt-8">
                  <p className="text-black text-lg lg:text-2xl">
                    {i18n.t('timeline')}
                  </p>
                  <div className="flex gap-4 border-b border-lighter-70 py-1 px-3 mb-5 mt-2">
                    <button
                      className={`${
                        tab === 'received' &&
                        'border-b border-primary-darker font-semibold text-primary'
                      }`}
                      onClick={() => {
                        setTab('received');
                      }}
                    >
                      Recebidos
                    </button>
                    <button
                      className={`${
                        tab === 'send' &&
                        'border-b border-primary-darker font-semibold text-primary'
                      }`}
                      onClick={() => {
                        setTab('send');
                      }}
                    >
                      Enviados
                    </button>
                  </div>
                  <RecognizeList
                    userId={selectedUser?.id}
                    reRenderToggle={reRenderToggle}
                    timelineType={tab}
                  />
                </div>
              </main>
            </div>
          </div>
          <div>
            <AutoMargin className="hidden lg:block w-full">
              <div className="shadow rounded-xl px-7 py-5 mt-5">
                <div className="bg-neutral p-3 rounded-xl">
                  <div>
                    <div className="flex items-center">
                      <Square
                        className="rounded bg-neutral-darker"
                        size="w-1/6"
                      >
                        <AvaliableMeritusIcon className="w-3/5 h-auto" />
                      </Square>
                      <div className="flex flex-col p-5">
                        <p className="font-bold">{`Você tem ${user?.meritMoney} créditos`}</p>
                        <p>para reconhecer seus colegas</p>
                      </div>
                    </div>
                    <button
                      className=" bg-primary-darker rounded px-5 py-2 text-white flex-grow w-full mt-2"
                      onClick={() => setShowMakeRecognition(true)}
                    >
                      {i18n.t('recognizeColleague')}
                    </button>
                  </div>
                </div>
              </div>
            </AutoMargin>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
