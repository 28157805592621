// set object that is sent to PopUpMission
// set Status

import { SelectedMission } from 'Components/Popup/PopUpMission';
import { useContext } from 'react';
import { MissionModel } from 'utils/api/mission';
import { i18n, GetLocaleString } from 'utils/i18n/index';
import { UserContext } from './Context/UserContext';
import Tag from './Tag';

export type Status =
  | 'done'
  | 'accepted'
  | 'notAccepted'
  | 'expired'
  | 'beforeStart';

interface MissionItemProps {
  mission: MissionModel;
  setSelectedMission: (selectedMission: SelectedMission) => void;
}

const MissionItem: React.FC<MissionItemProps> = ({
  mission,
  setSelectedMission,
}) => {
  const {
    name,
    meritus,
    doneBy,
    acceptedBy,
    id,
    endsAt,
    startsAt,
    companyValues,
    wallet,
  } = mission;
  const { user } = useContext(UserContext);
  if (!user?.id) return <>{i18n.t('itdidnotWork')}</>;

  /* const endsAtDate =
    endsAt &&
    new Date(endsAt).toLocaleString(i18n.t("dateLocaleString"), {
      year: '2-digit',
      day: '2-digit',
      month: '2-digit',
    });*/
  /* const startsAtDate =
    startsAt &&
    new Date(startsAt).toLocaleString(i18n.t("dateLocaleString"), {
      year: '2-digit',
      day: '2-digit',
      month: '2-digit',
    });*/

  const status = doneBy.find((x) => x.userId === user.id)
    ? 'done'
    : endsAt && new Date() > new Date(endsAt)
    ? 'expired'
    : startsAt && new Date() < new Date(startsAt)
    ? 'beforeStart'
    : acceptedBy.includes(user.id)
    ? 'accepted'
    : 'notAccepted';

  const buttonBehaviour = {
    done: {
      title: `${i18n.t('done')}`,
      secondTitle: null,
      styling: 'bg-lighter-70 text-darker-50',
    },
    beforeStart: {
      title: `${i18n.t('missionHasnotStarted')}`,
      secondTitle: null,
      styling: 'bg-lighter-70 text-darker-50',
    },
    expired: {
      title: `${i18n.t('expiredMission')}`,
      secondTitle: null,
      styling: 'bg-lighter-70 text-darker-50',
    },
    accepted: {
      title: `${i18n.t('finish')}`,
      secondTitle: null,
      styling: 'bg-secondary text-black',
    },
    notAccepted: {
      title: `${i18n.t('knowMore')}`,
      secondTitle: `${i18n.t('participate')}`,
      styling: 'bg-primary-darker text-white',
    },
  };

  return (
    <div
      key={id}
      className="rounded bg-neutral h-full w-full flex flex-col justify-between px-4 pt-3 pb-4  lg:p-6 lg:pt-2 max-w-xs"
    >
      <div>
        <div
          className={`text-xs lg:text-sm ${
            wallet?.style.theme ? 'text-' + wallet.style.theme : ''
          }`}
        >
          {wallet?.name || <br />}
        </div>
        <div className="flex items-start gap-2">
          <p className="text-darker-90 text-base lg:text-xl">{name}</p>
          <Tag>{`+${meritus}`}</Tag>
        </div>
        {/* <p className="text-sm mt-2">{description}</p> */}
        <div className="flex gap-2 flex-wrap mt-2">
          {companyValues &&
            companyValues.map((value) => (
              <Tag key={value} colorless>
                {value}
              </Tag>
            ))}
        </div>
      </div>
      <div className="mt-9">
        <p className="text-xs lg:text-sm">
          {status === 'expired'
            ? `${i18n.t('expired')} ${
                endsAt &&
                endsAt &&
                new Date(endsAt).toLocaleString(i18n.t('dateLocaleString'), {
                  year: '2-digit',
                  day: '2-digit',
                  month: '2-digit',
                })
              }`
            : status === 'beforeStart'
            ? `${i18n.t('start')} ${
                startsAt &&
                startsAt &&
                new Date(startsAt).toLocaleString(i18n.t('dateLocaleString'), {
                  year: '2-digit',
                  day: '2-digit',
                  month: '2-digit',
                })
              }`
            : (status === 'notAccepted' || status === 'accepted') && endsAt
            ? `${i18n.t('expires')} ${
                endsAt &&
                endsAt &&
                new Date(endsAt).toLocaleString(i18n.t('dateLocaleString'), {
                  year: '2-digit',
                  day: '2-digit',
                  month: '2-digit',
                })
              }`
            : ''}
        </p>
        <button
          className={`w-full rounded-full py-1  text-xs mt-1 ${buttonBehaviour[status].styling}`}
          onClick={() => {
            setSelectedMission({ mission, status });
          }}
        >
          {buttonBehaviour[status].title}
        </button>
      </div>
    </div>
  );
};

export default MissionItem;
