import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';

import AutoMargin from 'Components/AutoMargin';
import { UserContext } from 'Components/Context/UserContext';
import InputText from 'Components/Formik/InputText';
import InputTextArea from 'Components/Formik/InputTextArea';
// import MultiSpreadedSelectChild from 'Components/Formik/MultiSpreadedSelect';
// import { CompanyModel, getCompany } from 'utils/api/company';
import { updateUserProfile, updateUserProfileParams } from 'utils/api/users';
import { UpdateProfileSchema } from 'utils/validators/updateProfile';
import { toast } from 'react-toastify';
import { i18n } from 'utils/i18n/index'

interface UpdateUserProps {
  cancel: () => void;
}

const UpdateUser: React.FC<UpdateUserProps> = ({ cancel }) => {
  const { user, updateUserContext } = useContext(UserContext);
  // const [company, setCompany] = useState<CompanyModel | undefined>();
  const [image, setImage] = useState<File | undefined>();

  // useEffect(() => {
  //   if (!user?.companyId) return;
  //   getCompany(user?.companyId).then(setCompany);
  // }, [user]);

  const handleSubmit = (values: typeof initialValues, actions: any) => {
    actions.setSubmitting(true);
    const { confirm, password, ...rest } = values;
    const payload: updateUserProfileParams = { ...rest, file: image };

    if (!(password === '')) {
      payload.password = password;
    }

    toast.promise(updateUserProfile(payload), {
      pending: `${i18n.t("updatingProfile")}`,
      success: {
        render() {
          updateUserContext();
          actions.setSubmitting(false);
          cancel();
          return `${i18n.t("updatedProfile")}`;
        },
      },
      error: {
        render({ data }) {
          actions.setSubmitting(false);
          return `${i18n.t("somethingWrong")}`;
        },
      },
    });
  };

  const initialValues = {
    displayName: user?.displayName ?? '',
    bio: user?.bio ?? '',
    password: '',
    confirm: '',
    backupEmail: user?.backupEmail ?? ('' as string | undefined),
  };

  return (
    <AutoMargin className="bg-white rounded w-full p-5 lg:p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={UpdateProfileSchema}
      >
        {({ isSubmitting, submitForm }) => (
          <div>
            <p className="text-black text-xl">{i18n.t("editProfile")}</p>
            <p>
            {i18n.t("changeTheFieldsYouWantToUpdade")}
            </p>
            <div className="flex flex-col lg:grid lg:grid-cols-2 justify-around w-full gap-3 lg:gap-6 mt-3">
              <label className="flex flex-col text-xs w-full">
                {i18n.t("nickname/name")}
                <InputText name="displayName" placeholder={i18n.t("typeNickname")} />
              </label>
              <label className="flex flex-col text-xs w-full">
                {i18n.t("profilePicture")}
                <input
                  type="file"
                  id="file"
                  onChange={(e) => {
                    if (e.target.files === null) return;
                    const file = Array.from(e.target.files);
                    setImage(file[0]);
                  }}
                  className="w-full h-10 px-2 border border-lighter-70 rounded-input focus:outline-none"
                />
              </label>
              <label className="col-span-2 flex flex-col text-xs w-full">
                Bio
                <InputTextArea
                  className="h-20"
                  name="bio"
                  placeholder={i18n.t("tellMeMoreAboutYou")}
                />
              </label>

              {/*<label className="flex flex-col text-xs w-full">
                {i18n.t("newPassword")}
                <InputText
                  name="password"
                  password
                  placeholder= {i18n.t("typeTheNewPassword")}
                />
              </label>

              <label className="flex flex-col text-xs w-full">
                {i18n.t("repeatPassword")}
                <InputText
                  name="confirm"
                  password
                  placeholder={i18n.t("typeAgain")}
                />
                </label>*/}

              <label className="flex flex-col text-xs w-full">
                {i18n.t("secondaryEmail")}
                <InputText
                  name="backupEmail"
                  placeholder= {i18n.t("typeYourSecondaryEmail")}
                />
              </label>

              <label className="flex flex-col text-xs w-full">
                {i18n.t("cellphone")}
                <InputText name="mobile" placeholder={i18n.t("typeCellphone")} />
              </label>
              {/* <label className="flex flex-col text-xs w-full">
                Valores
                {company === undefined ? (
                  'loading...'
                ) : company.values === undefined ? (
                  'something went wrong'
                ) : (
                  <MultiSpreadedSelectChild
                    name="selectedValues"
                    className=" flex flex-wrap gap-2 mt-2 col-span-2"
                    options={company?.values.map((x) => ({
                      value: x.name,
                      label: x.name,
                    }))}
                  />
                )}
              </label> */}
            </div>
            <div className="flex mt-7 justify-between">
              <button
                className="rounded-full px-8 py-1 border"
                onClick={cancel}
              >
                {i18n.t("cancel")}
              </button>
              <button
                className="rounded-full bg-primary-darker px-8 py-1 text-white disabled:opacity-30"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {i18n.t("change")}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </AutoMargin>
  );
};

export default UpdateUser;
