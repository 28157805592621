import React, { useState, useEffect, useContext } from 'react';
import Navbar from 'Components/Navbar';
import { ReactComponent as FilterIcon } from 'assets/vectors/filter.svg';
import { ReactComponent as ArrowUp } from 'assets/vectors/arrowUp.svg';
import { ReactComponent as ArrowDown } from 'assets/vectors/arrowDown.svg';
import AutoMargin from 'Components/AutoMargin';
import { UserContext } from 'Components/Context/UserContext';
import { listUserGroupsMission, MissionModel } from 'utils/api/mission';
import PopUp from 'Components/PopUp';
// import PopUpMission from './PopUpMission';
import MissionItem, { Status } from 'Components/MissionItem';
import PopUpMission, { SelectedMission } from 'Components/Popup/PopUpMission';
import { i18n } from 'utils/i18n/index';
import MissionFilters from 'Components/Filters/MissionFilter';
import { toast } from 'react-toastify';

interface MissionsProps {}

const Missions: React.FC<MissionsProps> = () => {
  const { user, updateUserContext } = useContext(UserContext);

  // const [file, setFile] = useState<File | undefined>();
  // const [fileError, setFileError] = useState(false);
  // const [makingAction, setMakingAction] = useState(false);
  const [missions, setMissions] = useState<MissionModel[] | undefined>();
  const [selectedMission, setSelectedMission] = useState<{
    mission: MissionModel | null;
    status: Status;
  }>({ mission: null, status: 'done' });
  const [showMissionPopUp, setShowMissionPopUp] = useState(false);
  const [reRender, setRerenderer] = useState<boolean>(true);
  const [filters, setFilters] = useState({});
  const handleSetSelectedMission = (selectedMission: SelectedMission) => {
    setSelectedMission(selectedMission);
    setShowMissionPopUp(true);
  };
  const [showFilters, setShowFilters] = useState(false);
  const handleSetShowFilters = () => {
    setShowFilters(!showFilters);
  };
  const handleCancelPopUp = async (rerender = false) => {
    if (rerender) {
      await listUserGroupsMission().then(setMissions);
      updateUserContext();
    }
    setShowMissionPopUp(false);
  };

  useEffect(() => {
    (async () => {
      if (!user?.companyId) return new Error('deu ruim');
      try {
        if (reRender) {
          const missions = await listUserGroupsMission(filters);
          setMissions(missions);
          setRerenderer(false);
        }
      } catch (error) {
        toast.error('algo deu errado');
      }
    })();
  }, [user, reRender, filters]);

  const handleFilters = async (filter: Record<any, any>) => {
    setFilters(filter);
    setRerenderer(true);
  };

  return (
    <div className="text-sm lg:text-base">
      <PopUp show={showMissionPopUp}>
        <PopUpMission
          cancel={handleCancelPopUp}
          selectedMission={selectedMission}
        />
      </PopUp>
      <Navbar />
      <AutoMargin className="w-full mt-4 p-2 lg:mt-7 max-w-7xl">
        <div className="w-full">
          <p className="text-xs">
            DASHBOARD /{' '}
            <span className="text-primary">{i18n.t('missionMenu')}</span>
          </p>
          <p className="text-black text-xl lg:text-3xl mt-2 lg:mt-4">
            {i18n.t('missions')}
          </p>
          <div className="mt-5 flex flex-col bg-neutral shadow rounded-input px-5">
            {/* <div className='md:flex md:items-center md:pt-3 hidden' >
            <FilterIcon className="w-4 h-4 ml-2 text-darker-50 stroke-current"/>
            <p className='pl-3'>Filtros:</p>
            </div>
              <div className = 'hidden flex py-6 px-3'>
              <MissionFilters handleFilters={handleFilters} isReRendering={reRender}/>
              </div>*/}
            <div
              className="flex items-center px-2 py-2 w-full cursor-pointer justify-between"
              onClick={handleSetShowFilters}
            >
              <div className="flex">
                <FilterIcon className="w-3 mr-2 cursor-pointer text-darker-50 stroke-current hover:text-black" />
                {showFilters
                  ? `${i18n.t('HideFilters')}`
                  : `${i18n.t('showFilters')}`}
              </div>
              <div>{showFilters ? <ArrowUp /> : <ArrowDown />}</div>
            </div>
            {showFilters === true && (
              <MissionFilters
                handleFilters={handleFilters}
                isReRendering={reRender}
              />
            )}
          </div>
          <div className="w-full mt-4 p-2 lg:p-6 shadow rounded">
            <div className="flex flex-col items-center lg:grid lg:grid-cols-test gap-2">
              {missions === undefined ? (
                <p>{i18n.t('loading')}</p>
              ) : missions.length === 0 ? (
                <p>{i18n.t('noMissions')}</p>
              ) : (
                missions.map((mission) => (
                  <MissionItem
                    mission={mission}
                    setSelectedMission={handleSetSelectedMission}
                    key={mission.id}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </AutoMargin>
    </div>
  );
};

export default Missions;
