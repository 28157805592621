import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  listFeedResponse,
  listUserFeed,
  listUserSendFeed,
} from 'utils/api/feed';
import { i18n } from 'utils/i18n/index';
import RecognizeItem from './RecognizeItem';

interface RecognizeListProps {
  // recognitions: listFeedResponse | undefined;
  reRenderToggle: boolean;
  userId: string | undefined;
  timelineType: 'received' | 'send';
}

const RecognizeList: React.FC<RecognizeListProps> = ({
  reRenderToggle,
  userId,
  timelineType,
}) => {
  // counter for limit
  const limit = 20;
  // counter for offset
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [recognitions, setRecognitions] = useState<
    listFeedResponse | undefined
  >();

  useEffect(() => {
    if (userId === undefined) {
      return;
    }
    setRecognitions(undefined);
    window.scrollTo(0, 0);
    const newRecognitionsPromise =
      timelineType === 'received'
        ? listUserFeed(userId, { offset: 0, limit })
        : listUserSendFeed(userId, { offset: 0, limit });

    newRecognitionsPromise.then((newRecognitions) => {
      if (newRecognitions.length <= 0) return setHasMore(false);
      setRecognitions(newRecognitions);
      setOffset(limit);
      setHasMore(true);
    });
  }, [ userId, offset, timelineType, reRenderToggle ]);

  const fetchData = () => {
    if (userId === undefined || recognitions === undefined) {
      return;
    }
    listUserFeed(userId, { offset, limit }).then((newRecognitions) => {
      if (newRecognitions.length <= 0) return setHasMore(false);
      setRecognitions([...recognitions, ...newRecognitions]);
      setOffset(offset + limit);
      setHasMore(true);
    });
  };

  const refresh = async () => {
    if (userId === undefined) {
      return;
    }
    setOffset(0);

    const newRecognitions =
      timelineType === 'received'
        ? await listUserFeed(userId, { offset, limit })
        : await listUserSendFeed(userId, { offset, limit });

    if (newRecognitions.length <= 0) return setHasMore(false);
    setRecognitions(newRecognitions);
    setOffset(limit);
    setHasMore(true);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={recognitions?.length || 0} //This is important field to render the next data
        next={fetchData}
        hasMore={hasMore}
        loader={
          <div className="w-full text-center mt-3">
            <h4>{i18n.t('loading')}</h4>
          </div>
        }
        endMessage={
          <p className="text-center text-black mt-3">
            {i18n.t('youSawEverything')}
          </p>
        }
        // below props only if you need pull down functionality
        refreshFunction={refresh}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: 'center' }}>
            &#8595; {i18n.t('pullDownToUpdate')}
          </h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: 'center' }}>
            &#8593; {i18n.t('releaseToUpdate')}
          </h3>
        }
      >
        {recognitions?.map((recognition) => {
          return (
            <RecognizeItem
              key={recognition.id}
              recognitionObject={recognition}
            />
          );
        })}
      </InfiniteScroll>
    </>
  );
};

export default RecognizeList;
