import React, { useContext, useEffect, useState } from 'react';

import Navbar from 'Components/Navbar';
import MakeRecognition from 'Components/MakeRecognition';
import Sidebar from './Recognize/Sidebar';
import PopUp from 'Components/PopUp';
import { i18n } from 'utils/i18n/index';
import RecognizeItem from './Recognize/RecognizeItem';
import { getFeed, getFeedResponse } from 'utils/api/feed';
import { useParams } from 'react-router-dom';
import { UserContext } from 'Components/Context/UserContext';

interface RecognitionProps {}

const Recognition: React.FC<RecognitionProps> = () => {
  const { user } = useContext(UserContext);
  const { feedId } = useParams<{ feedId: string }>();

  const [feedObject, setFeedObject] = useState<
    getFeedResponse | null | undefined
  >(undefined);

  const [showMakeRecognition, setShowMakeRecognition] =
    useState<boolean>(false);

  useEffect(() => {
    if (feedId == null) return;
    if (user == null) return;
    getFeed(feedId).then(setFeedObject);
  }, [feedId, user]);

  const cancelMakeRecognition = (reload = false) => {
    setShowMakeRecognition(false);
  };

  return (
    <div className="text-sm lg:text-base">
      <PopUp show={showMakeRecognition}>
        <MakeRecognition cancel={cancelMakeRecognition} />
      </PopUp>
      <Navbar />
      <div className="overflow-hidden">
        <div className="lg:grid lg:grid-cols-3 lg:divide-solid lg:divide-x lg:divide-opacity-50 lg:divide-lighter-70">
          <div className="lg:col-span-2 lg:px-7">
            <div className="w-full px-2 lg:px-4">
              <div className="">
                <div className="text-xs mt-3 lg:mt-9">
                  DASHBOARD / FEED /{' '}
                  <span className="text-primary-darker">
                    {i18n.t('Recognition').toUpperCase()}
                  </span>
                </div>
                <p className="hidden lg:block text-black text-3xl mt-4">
                  {i18n.t('Recognition')}
                </p>
                <button
                  className="inline-block lg:hidden bg-primary-darker rounded px-5 py-2 text-white flex-grow w-full mt-2"
                  onClick={() => setShowMakeRecognition(true)}
                >
                  {i18n.t('recognizeColleague')}
                </button>
              </div>
              <main className="my-4">
                {feedObject === undefined ? (
                  <div>Carregando...</div>
                ) : feedObject === null ? (
                  <div>Reconhecimento não encontrado</div>
                ) : (
                  <RecognizeItem
                    recognitionObject={feedObject}
                    alwaysShowComment
                    hideMaximize
                  />
                )}
              </main>
            </div>
          </div>
          <Sidebar
            setShowMakeRecognition={setShowMakeRecognition}
            className="hidden lg:block"
          />
        </div>
      </div>
    </div>
  );
};

export default Recognition;
