import React, { useContext, useEffect, useState } from 'react';
import defaultProfile from '../../assets/images/default-profile.png';

import { ReactComponent as Banner } from '../../assets/temp/banner.svg';

import Card, { CardProps } from '../../Components/Card';
import Navbar from '../../Components/Navbar';
import { Link } from 'react-router-dom';
import PopUp from 'Components/PopUp';
import MakeRecognition from 'Components/MakeRecognition';
import LikeAndComment from 'Components/LikeAndComment';
import Tag from 'Components/Tag';
import { UserContext } from 'Components/Context/UserContext';
import { listCompanyFeed, listFeedResponse } from 'utils/api/feed';

export interface DashboardProps {}
//todo: delete later
const count = [
  {
    image: { src: defaultProfile, rounded: true },
    title: 'Caio',
    desc: 'Chefe dos chefes',
    button: { text: 'lalall' },
  },
  {
    image: { src: defaultProfile, rounded: true },
    title: 'aaaah',
    desc: 'eee',
    button: { text: 'lalall' },
  },
  {
    image: { src: defaultProfile, rounded: true },
    title: 'aaaah',
    desc: 'eee',
    button: { text: 'lalall' },
  },
] as Array<CardProps>;

const Dashboard: React.FC<DashboardProps> = (props) => {
  const { user } = useContext(UserContext);

  const [showMakeRecognition, setShowMakeRecognition] =
    useState<boolean>(false);
  const [recognitions, setRecognitions] = useState<listFeedResponse>([]);
  const [reRender, setRerenderer] = useState<boolean>(true);

  useEffect(() => {
    if (user?.companyId === undefined) return;
    if (reRender) {
      listCompanyFeed(user?.companyId).then(setRecognitions);
      setRerenderer(false);
    }
  }, [reRender, user?.companyId]);

  const cancelMakeRecognition = (reload = false) => {
    setRerenderer(reload);
    setShowMakeRecognition(false);
  };

  return (
    <div>
      <PopUp show={showMakeRecognition}>
        <MakeRecognition cancel={cancelMakeRecognition} />
      </PopUp>
      <Navbar />
      <div className="grid grid-cols-3 divide-solid divide-x divide-opacity-50 px-6">
        <div className="col-span-2"></div>
        {/* <div className="flex-grow pr-8 col-span-2 mt-3">
          <Banner className="w-full height-auto" />
          <main className="grid grid-cols-3 grid-rows-2 gap-6 gap-y-9 mt-3">
            <div className="col-span-2 shadow p-3 ">
              <p className="text-xl font-medium">Destaques da semana</p>
              <div className="grid grid-cols-3 gap-3">
                {count.map((value) => (
                  <Card {...value} badge={{ text: '20', className: '' }} />
                ))}
              </div>
            </div>
            <div className="col-span-1 shadow p-3">
              Recomendados
              <div className="flex flex-row gap-3 overflow-auto"></div>
            </div>
            <div className="col-span-2 shadow p-3">
              Aniversariantes
              <div className="grid grid-cols-3 gap-3 overflow-auto">
                {count.map((value) => (
                  <Card {...value} />
                ))}
              </div>
            </div>
            <div className="col-span-1 shadow p-3">
              Enquete
              <div className="flex flex-row gap-3 overflow-auto"></div>
            </div>
          </main>
        </div> */}
        <div className="mt-3 pl-8">
          <div className="shadow rounded-xl px-7 py-5">
            <div>
              <p className="text-lg font-bold">
                Voce esta perto de ser um master
              </p>
              <p className="">Mais 50 dianiod naion ni ni inin Master</p>
              <div className="bg-neutral p-3 rounded-xl">
                <img src="" alt="" />
                <div className="flex flex-col p-5">
                  <p className="font-bold">
                    Reconheça um colega e tenha mais chances de ser
                    reconhecido(a)
                  </p>
                  <button
                    className=" bg-primary-darker rounded px-5 py-2 text-white flex-grow"
                    onClick={() => setShowMakeRecognition(true)}
                  >
                    Reconheça um colega!
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow rounded-xl px-7 py-5 flex flex-col content-center items-center">
            <p className="text-lg font-bold self-start">
              Feed de Reconhecimento
            </p>
            {recognitions.map((recognition) => {
              return (
                <div
                  key={recognition.id}
                  className="bg-gray p-3 rounded-xl divide-y-2 divide-solid divide-gray mt-3"
                >
                  <div className="flex justify-around items-center mb-3 w-full">
                    <img
                      src={defaultProfile}
                      alt=""
                      style={{ width: '30px', height: '30px' }}
                      className="rounded-full bg-gray"
                    />
                    <div className="flex-grow mx-3">
                      <span className="font-bold">
                        {recognition.to?.displayName}{' '}
                      </span>
                      {`foi reconhecido(a) por ${recognition.from?.displayName} em ${recognition.additionalInformation.companyValue}`}
                    </div>
                    <Tag alternative className="flex-grow">
                      {`+ ${recognition.value}`}
                    </Tag>
                  </div>
                  {/* <LikeAndComment /> */}
                </div>
              );
            })}
            <div className="mt-3">
              quer ver mais?
              <Link to="/colaborador/reconhecimento" className="text-primary">
                Vá para o feed
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
