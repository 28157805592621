import React from 'react';

import { ReactComponent as ArrowIcon } from '../assets/vectors/arrow.svg';
import Square from './Square';

interface ArrowsProps {
  left?: boolean;
  right?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

/**
 *
 * @param left:boolean (default) se as left arrow
 * @param right:boolean set as right arrow
 * @param className: can receiva classname to change width text-color for icon bg-color and other css
 * @returns React Component Circled Arrow
 */
const Arrow: React.FC<ArrowsProps> = ({
  left,
  right,
  className,
  onClick = () => {},
}) => {
  const direction = right ? 'rotate-180' : '';
  return (
    <div className={className} onClick={onClick}>
      <Square>
        <ArrowIcon
          className={`${direction} stroke-current absolute w-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2`}
        />
      </Square>
    </div>
  );
};

export default Arrow;
