import React, { useContext, useEffect, useState } from 'react';

import { ReactComponent as InfoIcon } from 'assets/vectors/info.svg';
import { Calendar } from 'react-modern-calendar-datepicker';

import Navbar from 'Components/Navbar';
import MakeRecognition from 'Components/MakeRecognition';
import RecognizeList from './RecognizeList';
import Sidebar from './Sidebar';
import PopUp from 'Components/PopUp';
import { UserContext } from 'Components/Context/UserContext';
import { i18n } from '../../../utils/i18n/index';
import FeedFilters from 'Components/Filters/FeedFilter';
import { ReactComponent as FilterIcon } from 'assets/vectors/filter.svg';
import { ReactComponent as ArrowUp } from 'assets/vectors/arrowUp.svg';
import { ReactComponent as ArrowDown } from 'assets/vectors/arrowDown.svg';
interface RecognizeProps {}

const Recognize: React.FC<RecognizeProps> = () => {
  // const draft = 5;
  const { user, company } = useContext(UserContext);

  // const [showDrafts, setShowDrafts] = useState<boolean>(false);
  const [showMakeRecognition, setShowMakeRecognition] =
    useState<boolean>(false);
  const [showFilters, setShowFilters] = useState(false);
  const handleSetShowFilters = () => {
    setShowFilters(!showFilters);
  };
  const [reRender, setRerenderer] = useState<boolean>(true);
  const [filters, setFilters] = useState({});

  const cancelMakeRecognition = (reload = false) => {
    setRerenderer(reload);
    setShowMakeRecognition(false);
  };

  const handleFilters = async (filter: Record<any, any>) => {
    setFilters(filter);
    setRerenderer(true);
  };

  return (
    <div className="text-sm lg:text-base">
      <PopUp show={showMakeRecognition}>
        <MakeRecognition cancel={cancelMakeRecognition} />
      </PopUp>
      {/* <PopUp show={showDrafts}>
        <div className="bg-white">
          draft
          <button onClick={() => setShowDrafts(false)}>fechar</button>
        </div>
      </PopUp> */}
      <Navbar />
      <div className="overflow-hidden">
        <div className="lg:grid lg:grid-cols-3 lg:divide-solid lg:divide-x lg:divide-opacity-50 lg:divide-lighter-70">
          <div className="lg:col-span-2 lg:px-7">
            <div className="w-full px-2 lg:px-4">
              <div className="">
                <div className="text-xs mt-3 lg:mt-9">
                  DASHBOARD / <span className="text-primary-darker">FEED</span>
                </div>
                <p className="hidden lg:block text-black text-3xl mt-4">Feed</p>
                <button
                  className="inline-block lg:hidden bg-primary-darker rounded px-5 py-2 text-white flex-grow w-full mt-2"
                  onClick={() => setShowMakeRecognition(true)}
                >
                  {i18n.t('recognizeColleague')}
                </button>
              </div>
              {company?.config?.removeFeedFilter ? (
                <></>
              ) : (
                <div className="mt-5 flex flex-col bg-neutral shadow rounded-input px-5 w-full">
                  <div
                    className="flex items-center px-2 py-2 w-full cursor-pointer justify-between"
                    onClick={handleSetShowFilters}
                  >
                    <div className="flex">
                      <FilterIcon className="w-3 mr-2 cursor-pointer text-darker-50 stroke-current hover:text-black" />
                      {showFilters
                        ? `${i18n.t('HideFilters')}`
                        : `${i18n.t('showFilters')}`}
                    </div>
                    <div>{showFilters ? <ArrowUp /> : <ArrowDown />}</div>
                  </div>
                  {showFilters === true && (
                    <FeedFilters
                      handleFilters={handleFilters}
                      isReRendering={reRender}
                    />
                  )}
                </div>
              )}
              {/* <div className="flex justify-between bg-neutral rounded px-6 py-4 mt-7">
                <span className="flex items-center gap-2">
                  <InfoIcon />
                  {`Você tem ${draft} reconhecimentos em rascunho`}
                </span>
                <button
                  className="bg-primary-darker rounded-full text-xs text-white px-7"
                  onClick={() => setShowDrafts(true)}
                >
                  Ver rascunhos
                </button>
              </div> */}
              <main className="my-4">
                <RecognizeList
                  companyId={user?.companyId}
                  reRender={reRender}
                  setRerenderer={setRerenderer}
                  filters={filters}
                />
              </main>
            </div>
          </div>
          <Sidebar
            setShowMakeRecognition={setShowMakeRecognition}
            className="hidden lg:block"
          />
        </div>
      </div>
    </div>
  );
};

export default Recognize;
