import axios from 'axios';
import { tenorURL, tenorKey } from '../config';

export const TENOR_ANON_ID = "@tenor_anon_id";

type GifFormat = "gif" | "tinygif"

type Media = {
    [K in GifFormat]: MediaObject
}
export type MediaObject = {
    preview: string;
    url: string;
    dims: number[];
    size: number;
}

export type Result = {
    id: string;
    media: [Media]
}

export type TenorResponse = {
    results:Result[],
    next: string,
}

const setAnonId = (anonId: string) => {
    localStorage.setItem(TENOR_ANON_ID, anonId);
}

const getAnonId = async () => {
    const anonId = localStorage.getItem(TENOR_ANON_ID);
    if (anonId === 'undefined' || anonId === null){
        // console.log(anonId);
        const result = await tenorApi.get(`anonid?key=${tenorKey}`)
        // console.log(result);
        
        setAnonId(result.data.anonId)
        return result.data.anonId
    }
    return anonId
}

const tenorApi = axios.create({ baseURL: tenorURL });

export const searchGif = async (term ?: string, limit = 10, pos = '') => {
    if(!term) {
        return await tenorApi.get<TenorResponse>(`trending?locale=pt_BR&key=${tenorKey}&limit=${limit}&pos=${pos}&anon_id=${ await getAnonId()}`)
    }
    return await tenorApi.get<TenorResponse>(`search?q=${term}&locale=pt&key=${tenorKey}&limit=${limit}&pos=${pos}&anon_id=${ await getAnonId()}`)
}

export const registerShare = async (id: string, term: string) => {
    tenorApi.get(`registershare?id=${id}&locale=pt&key=${tenorKey}&q=term`)
}

