// import { getToken } from 'utils/jwt';
import { getToken } from 'utils/jwt';
import api from './axios';

export type UserModel = {
  id: string;
  companyId: string;
  registration: string;
  name: string;
  displayName: string;
  jobTitle: string;
  email: string;
  backupEmail: string;
  mobile: string;
  password: string;
  img: string;
  address: Address;
  selectedValues: string[];
  birthday: Date;
  bio: string;
  meritus: number;
  meritMoney: number;
  wallets:Array<{
    walletId: string;
    name: string;
    isDisabled: boolean;
    style:{
      theme: string;
    }
    id: string;
    fund: number;
    desc: string;
    companyId: string;
    categories: string[];
    fromTemplate: string
  }>
};

export type Address = {
  postalCode: string;
  street: string;
  numberAndComplement: string;
  city: string;
  state: string;
};

type getUserResponse = { value: UserModel };

export const getUser = async (userId: UserModel['id']) => {
  const {
    data: { value },
  } = await api.get<getUserResponse>(`user/${userId}`, {});
  return value;
};

export const updateUser = async (
  userId: UserModel['id'],
  params: Partial<UserModel>
) => {
  const response = await api.request({
    method: 'put',
    url: `user/${userId}`,
    data: params,
  });
  return response;
};

export type updateUserProfileParams = {
  displayName?: string;
  bio?: string;
  password?: string | undefined;
  backupEmail?: string | undefined;
  file?: File | undefined;
};

export const updateUserProfile = async (params: updateUserProfileParams) => {
  const formData = new FormData();
  const entries = Object.entries(params);
  entries.forEach((entry) => {
    if (entry[1] === undefined) return;
    if (Array.isArray(entry[1])) {
      entry[1].forEach((value) => formData.append(`${entry[0]}[]`, value));
    } else {
      formData.append(entry[0], entry[1]);
    }
  });
  
  const response = await api.request({
    method: 'put',
    url: `user/profile`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

type finishRegistrationModel = {
  user: UserModel;
  jwt: string;
};

export const finishRegistration = async (
  inviteId: string,
  params: Omit<UserModel, 'id' | 'registration' | 'companyId' | 'name'>
) => {
  const { data } = await api.request<finishRegistrationModel>({
    method: 'post',
    url: `user/finishRegistration/${inviteId}`,
    data: params,
  });
  return data;
};

export type DisplayNameResult = {
  displayName: string;
  id: string;
  name: string;
  email: string;
  img: string;
};

export type listDisplayNameResult = Array<DisplayNameResult>;

export const listDisplayName = async (): Promise<listDisplayNameResult> => {
  const { data } = await api.request({
    method: 'get',
    url: `display-name`,
  });
  return data;
};

export const fileUpload = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await api.request({
    method: 'post',
    url: 'upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
  return data;
};

export const whoAmI = async (): Promise<{
  userId: string;
  manager?: string[];
}> => {
  const { data } = await api.request({
    method: 'get',
    url: `whoAmI`,
  });
  return data;
};

export const tokenIsValid = async (): Promise<boolean> => {
  try {
    await api.request({
      method: 'get',
      url: `validate-token`,
    });
    return true;
  } catch {
    return false;
  }
};

export const logoutApi = async (): Promise<boolean> => {
  try {
    await api.request({
      method: 'get',
      url: `logout`,
    });
    return true;
  } catch {
    return false;
  }
};
