import { ErrorMessage, Field, FieldProps } from 'formik';
import React from 'react';
import { string } from 'yup';

interface InputTextProps {
  placeholder?: string;
  name: string;
  className?: string;
  password?: boolean;
  value?: string;
  withoutBorder?: boolean;
  onChangeCallback?: (value: string) => void;
  isDisabled?: boolean;
  autoComplete?: string;
}
const InputText: React.FC<InputTextProps> = (props) => {
  return <Field component={InputTextChild} {...props} />;
};

interface InputTextChildProps extends FieldProps, InputTextProps {}

const InputTextChild: React.FC<InputTextChildProps> = ({
  className,
  placeholder,
  password,
  field,
  form,
  meta,
  withoutBorder,
  onChangeCallback,
  isDisabled,
  autoComplete,
}) => {
  return (
    <div className={`${className} relative`}>
      <input
        type={password ? 'password' : 'text'}
        autoComplete={autoComplete}
        disabled={isDisabled}
        value={field.value}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.value);
          onChangeCallback && onChangeCallback(e.target.value);
        }}
        className={`w-full h-10 px-2 focus:outline-none ${
          withoutBorder ? '' : 'border'
        } text-xs lg:text-sm rounded-input ${
          form.touched[field.name] && form.errors[field.name]
            ? 'border-red'
            : 'border-lighter-70'
        }`}
        placeholder={placeholder}
      />
      <p className="text-xs text-red absolute font-bold">
        <ErrorMessage name={field.name} />
      </p>
    </div>
  );
};

export default InputText;
