import { url } from 'inspector';
import React, { useEffect, useState } from 'react';
import { i18n } from 'utils/i18n/index';

interface ProductCarouselProps {
  images: string[] | undefined | null;
  className?: string;
}

const ProductCarousel: React.FC<ProductCarouselProps> = ({
  images,
  className,
}) => {
  const [selectedImage, setSelectedImage] = useState<number>(0);

  useEffect(() => {
    setSelectedImage(0);
  }, [images]);

  return !images || images.length === 0 ? (
    <div>{i18n.t("noImagesToShow")}</div>
  ) : (
    <div className={`relative ${className}`}>
      <img
        src={images[selectedImage]}
        alt=""
        className="object-contain w-full h-full rounded"
      />
      <div className="absolute w-full pb-4 px-4 bottom-0 left-0">
        <div className="inline-flex gap-3 p-3 rounded bg-lighter-70 bg-opacity-50 w-full h-full overflow-x-auto overflow-y-auto">
          {images.map((image, index) => (
            <div
              key={index}
              className="object-contain flex-shrink-0 shadow w-12 h-12 rounded-input bg-cover bg-center"
              style={{backgroundImage: `url(${image})` }}
              onClick={() => {
                setSelectedImage(index);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;
