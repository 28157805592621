// import Tag from 'Components/Tag';
// import React, { useState, useEffect, useContext } from 'react';

// import AutoMargin from '../../Components/AutoMargin';
import AutoMargin from 'Components/AutoMargin';
import { Status } from 'Components/MissionItem';
import ReadMore from 'Components/ReadMore';
import Tag from 'Components/Tag';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  finishMission,
  MissionModel,
  subscribeToMission,
} from 'utils/api/mission';

import { ReactComponent as XIcon } from '../../assets/vectors/x.svg';
import { i18n } from 'utils/i18n/index'
export type SelectedMission = {
  mission: MissionModel | null;
  status: Status;
};

interface PopUpMissionProps {
  selectedMission: SelectedMission;
  cancel: (refresh?: boolean) => void;
}

const PopUpMission: React.FC<PopUpMissionProps> = ({
  selectedMission: { mission, status },
  cancel,
}) => {
  const [file, setFile] = useState<File | undefined>();
  const [fileError, setFileError] = useState(false);
  const [makingAction, setMakingAction] = useState(false);

  const buttonBehaviour = {
    done: {
      title: i18n.t("done"),
      secondTitle: null,
      styling: 'bg-lighter-70 text-darker-50',
      onClick: (id: string | undefined) => {},
    },
    beforeStart: {
      title: i18n.t("missionHasnotStarted"),
      secondTitle: null,
      styling: 'bg-lighter-70 text-darker-50',
      onClick: (id: string | undefined) => {},
    },
    expired: {
      title: `${i18n.t("exPired")!}`,
      secondTitle: null,
      styling: 'bg-lighter-70 text-darker-50',
      onClick: (id: string | undefined) => {},
    },
    accepted: {
      title: i18n.t("finish"),
      secondTitle: null,
      styling: 'bg-secondary text-black',
      onClick: (id: string | undefined) => {
        if (!id) return alert('something went wrong');

        if (mission?.approveType === 'proof' && !file)
          return setFileError(true);

        setMakingAction(true);

        const promise = finishMission(id, file);
        toast.promise(promise, {
          error: {
            render: () => {
              setMakingAction(false);
              return i18n.t("somethingWrong");
            },
          },
          pending: i18n.t("finishingTheMission"),
          success: {
            render: () => {
              setFileError(false);
              setMakingAction(false);
              cancel(true);
              return i18n.t("missionCompletedSuccessfully");
            },
          },
        });
      },
    },
    notAccepted: {
      title: i18n.t("knowMore"),
      secondTitle: i18n.t("participate"),
      styling: 'bg-primary-darker text-white',
      onClick: (id: string | undefined) => {
        if (!id) return alert('something went wrong');
        setMakingAction(true);
        const promise = subscribeToMission(id);
        toast.promise(promise, {
          error: {
            render: () => {
              setMakingAction(false);
              return i18n.t("somethingWrong");
            },
          },
          pending: i18n.t("acceptingMission"),
          success: {
            render: () => {
              setFileError(false);
              setMakingAction(false);
              cancel(true);
              return i18n.t("missionAcceptedSuccessfully");
            },
          },
        });
      },
    },
  };

  if (!mission) return <div>{i18n.t("somethingWrong")}</div>;

  /*const endsAtDate =
    mission.endsAt &&
    new Date(mission.endsAt).toLocaleString(i18n.t("dateLocaleString"), {
      year: '2-digit',
      day: '2-digit',
      month: '2-digit',
    });*/
  /*const startsAtDate =
    mission.startsAt &&
    new Date(mission.startsAt).toLocaleString(i18n.t("dateLocaleString"), {
      year: '2-digit',
      day: '2-digit',
      month: '2-digit',
    });*/
  return (
    <AutoMargin className="rounded bg-neutral p-4 lg:p-10 lg:mx-16 max-w-7xl min-w-290">
      <div className="flex justify-end">
        <div
          onClick={() => {
            cancel();
            setFileError(false);
          }}
          className="cursor-pointer"
        >
          <XIcon className="text-black fill-current stroke-current w-4" />
        </div>
      </div>
      <div className="flex items-start gap-2">
        <p className="text-darker-90 text-xl">{mission?.name}</p>
        <Tag>{`+${mission?.meritus}`}</Tag>
      </div>
      <div className="flex gap-2 flex-wrap mt-2">
        {mission?.companyValues &&
          mission.companyValues.map((value) => <Tag colorless>{value}</Tag>)}
      </div>
      <ReadMore
        className="text-sm mt-5"
        text={mission.description}
        numSplit={500}
      />
      {mission?.approveType === 'proof' && status === 'accepted' && (
        <div>
          <hr className="border-lighter-70 my-3" />
          <p className="text-sm">
            {i18n.t("attachFile")}
          </p>
          <input
            type="file"
            id="file"
            onChange={(e) => {
              if (e.target.files === null) return setFile(undefined);
              setFileError(false);
              const file = Array.from(e.target.files);
              setFile(file[0]);
            }}
            className="w-full h-10 px-2 border mt-3 mb-3 border-lighter-70 rounded-input focus:outline-none"
          />
          <p className="text-sm text-red" hidden={!fileError}>
            {i18n.t("mustAttachFile")}
          </p>
        </div>
      )}
      <p className="text-xs lg:text-sm">
        {status === 'expired'
          ? `${i18n.t("expired")} ${mission.endsAt &&
              new Date(mission.endsAt).toLocaleString(i18n.t("dateLocaleString"), {
              year: '2-digit',
              day: '2-digit',
              month: '2-digit',
            })}`
          : status === 'beforeStart'
          ? `${i18n.t("start")}: ${mission.startsAt &&
              new Date(mission.startsAt).toLocaleString(i18n.t("dateLocaleString"), {
              year: '2-digit',
              day: '2-digit',
              month: '2-digit',
            })}`
          : (status === 'notAccepted' || status === 'accepted') &&
            mission?.endsAt
          ? `${i18n.t("expires")} ${mission.endsAt &&
              new Date(mission.endsAt).toLocaleString(i18n.t("dateLocaleString"), {
              year: '2-digit',
              day: '2-digit',
              month: '2-digit',
            })}`
          : ''}
      </p>
      <div className="flex gap-2 justify-end mt-5">
        <button
          className="text-xs px-7 py-2 border rounded-full"
          onClick={() => cancel()}
        >
          {i18n.t("cancel")}
        </button>
        <button
          className={`py-2 px-7 text-xs rounded-full disabled:opacity-30 ${buttonBehaviour[status].styling}`}
          onClick={() => buttonBehaviour[status].onClick(mission?.id)}
          disabled={makingAction}
        >
          {buttonBehaviour[status]?.secondTitle ||
            buttonBehaviour[status].title}
        </button>
      </div>
    </AutoMargin>
  );
};

export default PopUpMission;
