import React from 'react';
import { Formik, Form } from 'formik';

import { ReactComponent as LogoIcon } from 'assets/vectors/logo.svg';
import { ReactComponent as GiftIcon } from 'assets/vectors/gift.svg';
import img from 'assets/images/login.png';
import googleLogo from 'assets/images/g-normal.png';
import microsoftLogo from 'assets/images/microsoft-logo.png';
import ssotLogo from 'assets/images/sso.png';

import { authenticateSchema } from 'utils/validators/authentication';
import { useState } from 'react';

import AutoMargin from 'Components/AutoMargin';
import PopUp from 'Components/PopUp';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import InputText from 'Components/Formik/InputText';
import RequestPasswordRecovery from 'Components/RequestPasswordRecovery';
import Loading from 'Components/Animations/Loading';
import { LanguageSwitcher } from '../../Components/LanguageSwitcher';
import { i18n } from 'utils/i18n/index';
import { env } from 'config';

export interface LoginProps {}
const Login: React.FC<LoginProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const {
    user,
    loginWithEmail,
    loginWithGoogle,
    loginWithMicrosoft,
    loginWithOpendId,
  } = useContext(UserContext);

  return (
    <div className="flex justify-between items-stretch min-h-screen">
      <PopUp show={showPasswordRecovery}>
        <RequestPasswordRecovery
          cancel={() => setShowPasswordRecovery(false)}
        />
      </PopUp>

      <div className="hidden flex-col justify-around p-16 min-h-full bg-primary-darker w-7/12 rounded-r-xl lg:flex">
        <p className="font-normal text-7xl text-white">
          {i18n.t('boostText')}
          <br />
          {i18n.t('theValue')} <br />
          {i18n.t('ofConnections')}
        </p>
        <div className="flex justify-between">
          <div className="rounded-full w-1/4">
            <img src={img} alt="" className="rounded-full w-full h-auto" />
          </div>
          <div className=" relative transform translate-y-1/2 w-1/4">
            <div className="absolute transform -translate-y-1/2 rounded-full bg-secondary w-full h-full" />
            <div className="relative flex justify-center items-center w-full pb-full">
              <GiftIcon className="absolute stroke-current text-white w-1/2" />
              <div className="absolute transform -translate-x-1/2 border-r-0 border-solid border-white border w-screen h-full border-opacity-30" />
              <div className="absolute transform translate--1/2 -translate-y-1/2 border-b-0 border-solid border-white border w-full h-screen border-opacity-30" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute right-3 top-2 lg:absolute lg:right-6 lg:top-3" /*style={{position:"absolute", right:"50px", top: "10px"}}*/
      >
        <LanguageSwitcher />
      </div>
      <main className="flex justify-center items-center flex-grow">
        <AutoMargin className="w-full px-4 lg:w-96">
          <div className="flex gap-3 items-center font-bold text-4xl text-black mb-16 mt-10">
            <LogoIcon />
            meritu
          </div>
          <div className="font-medium text-xl text-black">
            {i18n.t('accessYourAccount')}
          </div>
          <div className="mb-10">{i18n.t('joinThePlatform')}</div>
          {user === undefined ? (
            <Loading />
          ) : (
            <>
              <div className="flex flex-col justify-center items-center mt-5 gap-3">
                <div>
                  <p className="text-black">{i18n.t('loginWith')}</p>
                </div>
                {env === 'gerdau' ? (
                  <>
                    <button
                      className="bg-white relative rounded-input shadow w-full h-11 flex justify-center items-center"
                      onClick={loginWithOpendId}
                    >
                      <img
                        className="w-16 absolute left-1"
                        src={ssotLogo}
                        alt="SSO"
                      />
                      <p>SSO</p>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="bg-white relative rounded-input shadow w-full h-11 flex justify-center items-center"
                      onClick={loginWithGoogle}
                    >
                      <img
                        className="w-10 h-10 absolute left-0"
                        src={googleLogo}
                        alt="google"
                      />
                      <p>Google</p>
                    </button>
                    <button
                      className="bg-white relative rounded-input shadow w-full h-11 flex justify-center items-center"
                      onClick={loginWithMicrosoft}
                    >
                      <img
                        className="w-8 h-8 absolute left-1"
                        src={microsoftLogo}
                        alt="microsoft"
                      />
                      <p>Microsoft</p>
                    </button>
                  </>
                )}
              </div>
              {env === 'gerdau' ? (
                <></>
              ) : (
                <>
                  <div className="mt-9 flex items-center justify-center gap-2">
                    <hr className="border-lighter-70 w-full" />
                    <p className="flex-grow-0">{i18n.t('or')}</p>
                    <hr className="border-lighter-70 w-full" />
                  </div>
                  <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={authenticateSchema}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(true);
                      loginWithEmail(values).catch((error) => {
                        actions.setSubmitting(false);
                        error.response?.status === 401
                          ? setErrorMessage(i18n.t('invalidEmailPassword'))
                          : setErrorMessage(i18n.t('somethingWrong'));
                      });
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form className="flex flex-col mb-20">
                        <p className="text-xs text-red absolute font-bold">
                          {errorMessage}
                        </p>
                        <label className="flex flex-col text-xs w-full mt-6">
                          {i18n.t('email')}
                          <InputText
                            name="email"
                            placeholder={i18n.t('typeYourEmail')}
                          />
                        </label>
                        <label className="flex flex-col text-xs w-full mt-6">
                          {i18n.t('password')}
                          <InputText
                            name="password"
                            password
                            autoComplete="off"
                            className="mt-2"
                            placeholder={i18n.t('typeYourPassword')}
                          />
                        </label>
                        <div className="flex justify-end mt-2">
                          <button
                            onClick={() => setShowPasswordRecovery(true)}
                            type="button"
                            className="text-primary-darker mr-2 text-sm hover:text-primary"
                          >
                            {i18n.t('forgotPassword')}
                          </button>
                        </div>
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          className="py-2 bg-primary-darker text-white box-border rounded-full mt-10 disabled:opacity-30"
                        >
                          {i18n.t('login')}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </>
          )}
        </AutoMargin>
      </main>
    </div>
  );
};

export default Login;
